import React from 'react';
import Labeled from '../admin-on-rest/mui/input/Labeled';

const ConditionalField = ({record, condition, children, resource, basePath}) => {
    if (condition(record)) {
        if (Array.isArray(children)) {
            return (
                <div>
                    {React.Children.map(children, child => (
                        <div
                            key={child.props.source}
                            style={child.props.style}
                            className={`aor-input-${child.props.source}`}
                        >
                            <Labeled
                                record={record}
                                resource={resource}
                                basePath={basePath}
                                label={child.props.label}
                                source={child.props.source}
                                disabled={false}
                            >
                                {child}
                            </Labeled>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div key={children.props.source} style={children.props.style} className={`aor-input-${children.props.source}`}>
                {
                    <Labeled
                        record={record}
                        resource={resource}
                        basePath={basePath}
                        label={children.props.label}
                        source={children.props.source}
                        disabled={false}
                    >
                        {children}
                    </Labeled>
                }
            </div>
        );
    } else {
        return null;
    }
};

export default ConditionalField;
