import React, { Component, Fragment } from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, Fields, FieldArray} from "redux-form";
import classNames from 'classnames';
import RichTextInput from "aor-rich-text-input";
import get from "lodash/get";
import moment from 'moment';
import './font.css';
import storage from "../../providers/RestClient/storage";
import Delete from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { API_ENDPOINT } from "../../constants";
import { transparent } from 'material-ui/styles/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from 'material-ui/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from 'material-ui/MenuItem';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref){
    return <Slide direction='up' ref={ref} {...props}/>
})

class StudentRow extends Component {

    addPreconcepto = false;  //Para activar o desactivar crear Preconcepto
    
    constructor(props){
        super(props);
        if(this.props.student.comments.find(comment => comment.createdBy === storage.load('userId'))){
            this.addPreconcepto = true;
        } 

        this.state = {
            expanded: false,
            users: [],
            commenttypes: [],
            addPreconcepto: this.addPreconcepto,
            open: false,
            remove: false,
            index: -1,
            desde: '',
            tcSelect: ''   
        };

        this.handleClickOpen = this.handleClickOpen.bind(this)
        this.handleClickClose = this.handleClickClose.bind(this)
    }

    shouldComponentUpdate(){
        return true
    }

    handleClickOpen({comments,index,desde}){
        this.setState(state => ({
            open: true,
            comments: comments,
            index: index,
            desde: desde
        }))
    }

    handleClickClose(remove = false){
        this.setState(state => ({
            open: false
        }))

        if(remove){
            this.setState(state =>({
                remove: true
            }))
        }
    }

    handleChange = (event) => {
        this.setState(state => ({
            tcSelect: event.target.value
        }))
    };

    handleToggleExpanded = event => {
        this.setState(state => ({ expanded: !state.expanded }));
        event.stopPropagation();
    };

    renderActionInput = (action) => (fields) =>
        <span className={this.props.classes.actionInput} >
            {`${get(fields, `${fields.names[0]}.input.value`)} - ${get(fields, `${fields.names[1]}.input.value`)}`}
        </span>;

    renderInput = ({ input }) => <span>{input.value}</span>;

    renderLabeledInput = ({ input, label }) => (
        <div style={{ marginBottom: 8 }}>
            <span className={this.props.classes.label} style={{ fontSize: '14px', fontWeight: 'bold' }}>{label}</span>
            <div>{input.value}</div>
        </div>
    );

    renderCenterAndProgram = (fields) => {
        return (
            <span>
                {`${(this.props.centers.data.find(center => center.id === get(fields, `${fields.names[0]}.input.value`)) || {}).name} - 
                ${(this.props.programs.data.find(program => program.id === get(fields, `${fields.names[1]}.input.value`)) || {}).name}`}
            </span>
        )
    };

    renderRequestWithCenterAndProgram = (fields) => {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: get(fields, `${fields.names[0]}.input.value`) }} />
                <span>
                    {`${(this.props.centers.data.find(center => center.id === get(fields, `${fields.names[1]}.input.value`)) || {}).name} - 
                  ${(this.props.programs.data.find(program => program.id === get(fields, `${fields.names[2]}.input.value`)) || {}).name}`}
                </span>
            </div>
        )
    };

    renderConditional = ({ input, label }) => (
        <div style={{ marginBottom: 8 }}>
            <span className={this.props.classes.label} style={{ fontSize: '14px', fontWeight: 'bold' }}>{label}</span>
            <div>
                {(this.props.studentConditionalities.data.find(studentConditionality => studentConditionality.id === input.value) || {}).name}
            </div>
        </div>
    );

    renderRichTextInput = ({ input }) =>
        <div style={{ width: 200 }}><RichTextInput input={{ ...input }} toolbar={[['bold']]} /></div>;

    renderRichTextField = ({ input }) => (
        <div>
            <span className={this.props.classes.label} style={{ fontSize: '14px', fontWeight: 'bold' }}>Descripción</span>
            {
                input.value ? <div dangerouslySetInnerHTML={{ __html: input.value }} /> : <div>No tiene descripción.</div>
            }
        </div>
    );

    renderHtmlField = ({ input }) => (
        <span>
            {
                input.value ? <div dangerouslySetInnerHTML={{ __html: input.value }} /> : ''
            }
        </span>
    );

    renderFileField = ({ input }) => (
        <div>
            <span className={this.props.classes.label} style={{ fontSize: '14px', fontWeight: 'bold' }}>Archivos de soporte</span>
            {
                input.value && input.value.length > 0 ? input.value.map((attachment, index) =>
                    <li key={index}>
                        <a href={get(attachment, 'url')} title={get(attachment, 'filename')} target="_blank"
                            rel="noopener noreferrer">
                            {get(attachment, 'filename')}
                        </a>
                    </li>
                ) : <div>No tiene archivos de soporte.</div>
            }
        </div>
    );

    renderDateField = ({ input }) => input.value && <span>{moment(input.value).format('L')}</span>;

    renderCouncil(id) {

        let html = {
            component1: null,
            component2: null,
            component3: null
        }


        if (storage.load('concejo') == 'Consejo de Instituto') {
            html.component1 = <Fragment>
                <TableCell><Field name={`${id}.inline_comment_I`}
                    component={this.renderRichTextInput} /></TableCell>
                <TableCell><Field name={`${id}.inline_feedback_I`}
                    component={this.renderRichTextInput} /></TableCell>
            </Fragment>;
            html.component2 = <Fragment>
                <Field name={`${id}.inline_comment`}
                    component={this.renderLabeledInput} label='Comentario Concejo Programas' />
                <Field name={`${id}.inline_feedback`}
                    component={this.renderLabeledInput} label='Concepto Concejo Programas' />
            </Fragment>;
            html.component3 = <Fragment>
                <TableCell><Field name={`${id}.comments[0].description`}
                    component={this.renderRichTextInput} /></TableCell>

            </Fragment>
        } else {
            html.component1 = <Fragment>
                <TableCell><Field name={`${id}.inline_comment`}
                    component={this.renderRichTextInput} /></TableCell>
                <TableCell><Field name={`${id}.inline_feedback`}
                    component={this.renderRichTextInput} /></TableCell>
            </Fragment>;
            html.component2 = null;
        }

        return html;
    }

    fetchClients() {
        const url = `${API_ENDPOINT}/clients`;
        const token = storage.load("token");
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: token
        });

        fetch(url, { headers })
            .then(response => response.json())
            .then(client => {
                this.setState(state => ({ users: client }));
            })
    }

    fetchCommentType() {
        const url = `${API_ENDPOINT}/commenttypes`;
        const token = storage.load("token");
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: token
        });

        fetch(url, { headers })
            .then(response => response.json())
            .then(commenttype => {
                this.setState(state => ({ commenttypes: commenttype }));
            })
    }

    _renderPreconcepto = (element) => {
        let comment = element.input.value;
        let user = this.state.users.filter(user => user.id === comment.createdBy)                          
        let commentType = this.state.commenttypes.filter(commentElement => commentElement.id === comment.type)

        return <Fragment>
                    <b>{user[0]?.username + ': '}</b>{(commentType[0]?.name ?? ' ') + ' - ' + comment?.description}
               </Fragment>
    }

    disabledAddPreconcepto({comments,index,desde}){

        let c = comments.getAll()

        if(desde == 'remove'){
            if(c[index].createdBy === storage.load('userId')){
                this.setState(state => ({
                    addPreconcepto:false
                }))
            }
            if(this.state.remove && !this.state.open) comments.remove(index)
            
        }else if(desde == 'push'){
            this.setState(state => ({
                addPreconcepto:true
            }))

            comments.push({
                createdBy: storage.load('userId'),
                type: document.getElementById('TC-pre')?.value,
                description: document.getElementById('desc-pre')?.value
            })
        }
        
    }

    renderPreConceptos = ( {fields} ) => {
        let inputStyle = {
            width: '200px',
            border: '0 0 1px 0'
        }

        let html = [];
        let comments = fields;

        html =  comments.map((element, index) => {
            return (<li key={index}>
                        <Field name={`${element}`} component={this._renderPreconcepto}/>
                        <Button type='button' variant='outlined' style={{border:0, color:transparent}} 
                        onClick={() => {
                            this.handleClickOpen({comments: comments, index: index, desde: 'remove'})
                            }}>
                            <Delete color='action' sx={{fontSize:5}}/>
                        </Button>
                    </li>);
        });


        if (storage.load('concejo') == 'Consejo de Instituto') {

            const userId = storage.load('userId');
            const user = this.state.users.find(user => user.id === userId)

            return (
            <div style={{ marginBottom: 8 }}>
                <span className={this.props.classes.label} style={{ fontSize: '14px', fontWeight: 'bold' }}>Pre-conceptos</span>
                <ol>
                    {
                    [...html,
                    ...[<li key={1000}>
                         <div>
                            
                            <TextField style={{width:'15%', marginRight:'1rem'}}  id='user-pre' value={user.username} disabled={true} variant='filled' size='small'></TextField>
                            
                                <select 
                                style={{width:'10rem', height:'2rem', border:'none', borderBottom: '1px solid lightgrey', color: 'gray', fontSize: 'medium', borderRadius:'3px', marginRight: '10px'}}
                                id='TC-pre'>
                                    {this.state.commenttypes.map((element,index) => {
                                        return <option key={index} value={element.id}>{element.name}</option>
                                    })}
                                </select>

                            <TextField style={{width:'15%', marginRight:'1rem'}} id='desc-pre' placeholder='Descripción' variant='filled' size='small' rows={2} rowsMax={2} multiLine></TextField>
                            
                            <Button 
                            style={{width:'10%', marginRight:'1rem'}}
                             variant='contained'
                             disabled={this.state.addPreconcepto}
                             onClick={() => {this.disabledAddPreconcepto({comments: comments,desde: 'push'})}}
                             size='small'
                            >Agregar</Button>  

                         </div>
                        </li>
                       ]
                    ]
                    }
                </ol>           
            </div>
            )
        }
        return <div style={{ marginBottom: 8 }}>   </div>
    };

    render() {
        const { id, classes, student } = this.props;
        const { expanded } = this.state;

        if (this.state.users.length == 0) {
            this.fetchClients();
        }

        if (this.state.commenttypes.length == 0) {
            this.fetchCommentType();
        }

        return (


            <Fragment>

                <TableRow key={`${id}-row`}>

                    <TableCell
                        padding="none"
                        className={classes.expandIconCell}
                    >
                        <IconButton
                            className={classNames(classes.expandIcon, {
                                [classes.expanded]: expanded,
                            })}
                            component="div"
                            tabIndex={-1}
                            aria-hidden="true"
                            role="expand"
                            onClick={this.handleToggleExpanded}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </TableCell>
                    <TableCell><Fields names={[`${id}.student_name`, `${id}.student_code`]}
                        component={this.renderActionInput(this.props.openDetail)} /></TableCell>
                    <TableCell><Field name={`${id}.request_date`}
                        component={this.renderDateField} /></TableCell>
                    <TableCell><Fields names={[`${id}.request`, `${id}.center`, `${id}.program`]}
                        component={this.renderRequestWithCenterAndProgram} /></TableCell>


                    {this.renderCouncil(id).component1}


                </TableRow>
                {
                    expanded &&
                    <TableRow key={`${id}-expand`}>
                        <TableCell colSpan={8} role="expand-content">
                            {this.renderCouncil(id).component2}
                            <div style={{ marginBottom: '10px' }}><FieldArray name={`${id}.comments`} component={this.renderPreConceptos} /></div>
                            <div style={{ marginBottom: '10px' }}><Field name={`${id}.description`} component={this.renderRichTextField} /></div>
                            <div className={this.props.classes.expand} style={{ width: '70%', margin: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <Field name={`${id}.start_period`}
                                        component={this.renderLabeledInput} label='Inicio' />
                                    <Field name={`${id}.level`}
                                        component={this.renderLabeledInput} label='Nivel' />
                                    <Field name={`${id}.gpa`}
                                        component={this.renderLabeledInput} label='Promedio' />
                                    <Field name={`${id}.cc`}
                                        component={this.renderLabeledInput} label='C.C' />
                                    <Field name={`${id}.ca`}
                                        component={this.renderLabeledInput} label='C.A' />
                                    <Field name={`${id}.cm`}
                                        component={this.renderLabeledInput} label='C.M' />
                                    <Field name={`${id}.student_conditionality`}
                                        component={this.renderConditional} label='Cond' />
                                </div>

                                <div style={{ marginBottom: '10px' }}><Field name={`${id}.attachments`} component={this.renderFileField} /></div>

                            </div>
                        </TableCell>
                    </TableRow>
                }
                <Dialog 
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
                    
                    <DialogTitle id='alert-dialog-title'>
                        {"¿Desea eliminar el Pre-concepto?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button 
                        onClick={async () => {
                            await this.handleClickClose(true)
                            await this.disabledAddPreconcepto({comments: this.state.comments, index: this.state.index, desde: this.state.desde})
                            }}>
                            Aceptar
                        </Button>
                        <Button onClick={() => this.handleClickClose()}>Cancelar</Button>
                    </DialogActions>

                </Dialog>
            </Fragment>
        );
    }
}

StudentRow.propTypes = {
    expanded: PropTypes.bool,
    users: PropTypes.object,
    commenttypes: PropTypes.object,
    addPreconcepto: PropTypes.bool
}

export default StudentRow;