import React, { Component } from 'react';

import FormField from '../admin-on-rest/mui/form/FormField';
import ReferenceIssuesInput from '../ReferenceIssuesInput';
import SelectIssuesInput from '../SelectIssuesInput';

import storage from '../../providers/RestClient/storage';

import get from 'lodash/get';

import { ISSUE_TYPE_STUDENTS } from '../../constants';

const ACTIVITIES_KEY = 'activities';
const ISSUES_KEY = 'issues';
const STUDENTS_ACTIVITY_TITLE = 'studiantes';

const defaultIssuesFilter = {
    fields: {
        id: true,
        title: true,
        student_name: true,
        state: true,
        center: true,
        program: true,
        student_issue_type: true,
        description: true
    },
};

const selectInputOptions = {
    fullWidthInput: true,
    fullWidth: true,
    listStyle: {
        maxHeight: 150,
        overflow: 'auto'
    },
    menuProps: {
        menuItemStyle: {
            whiteSpace: 'normal',
            lineHeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            paddingTop: '16px',
            paddingBottom: '16px',
            p: {
                margin: 0
            }
        }
    }
};

class IssuesInput extends Component {
    renderIssueTitle = (choice, data) => {

        return (
            choice.title ||
            `${choice.student_name}
          
             ${choice.student_issue_type ? `- ${(data.studentIssueTypes[choice.student_issue_type] || {}).name}` : ''}
             ${choice.program ? `- ${(data.programs[choice.program] || {}).name}` : ''}
             ${choice.center ? `- ${(data.centers[choice.center] || {}).name}` : ''}`
        );
    };

    renderInput = () => {
        const {
            record
        } = this.props;

        return (

            <ReferenceIssuesInput
                filter={{
                    ...defaultIssuesFilter,
                    where: { act: record.id }
                }}
                transformFilter={(filter, source, record) => {


                    if (source.includes(ACTIVITIES_KEY) && source.includes(ISSUES_KEY)) {
                        const currentActivity = get(record, source.split('.')[0]);

                        if (currentActivity.title.includes(STUDENTS_ACTIVITY_TITLE)) {
                            return {
                                ...filter,
                                where: {
                                    ...filter.where,
                                    type: ISSUE_TYPE_STUDENTS,

                                }
                            };
                        }
                    }

                    return filter;
                }}
                perPage={200}
                allowEmpty
                {...this.props}>
                <SelectIssuesInput
                    optionText={this.renderIssueTitle}
                    hasDelete={true}
                    options={selectInputOptions} />
            </ReferenceIssuesInput>
        );
    };

    render() {
        return <FormField input={this.renderInput()} {...this.props} />;
    }
}

export default IssuesInput;
