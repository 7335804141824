import React, {Component} from 'react';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import Divider from 'material-ui/Divider';

const Item = SortableElement((props) => {
    return (
        <div style={props.style}>
            {`${props.internal + 1}. ${props.value.title}`}
            {props.internal >= 0 && props.internal < props.count - 1 && <Divider/>}
        </div>
    );
});

const List = SortableContainer((props) => {
    const {
        input
    } = props;

    return (
        <div style={{margin: '20px'}}>
            {
                input && input.value &&
                input.value.map((activity, index) =>
                    <Item style={{margin: '10px'}} value={activity} key={index} index={index} internal={index} count={input.value.length} {...props} />
                )
            }
        </div>
    );
});

class Sortable extends Component {
    render() {
        const {
            input
        } = this.props;

        const OnSortEnd = ({oldIndex, newIndex}) => { input.onChange(arrayMove(input.value, oldIndex, newIndex)) };

        return (
            <List onSortEnd={OnSortEnd} {...this.props} />
        );
    }
}

Sortable.defaultProps = {
    addField: true,
    addLabel: false
};

export default Sortable;