import React, { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import autoprefixer from 'material-ui/utils/autoprefixer';
import CircularProgress from 'material-ui/CircularProgress';
import withWidth from 'material-ui/utils/withWidth';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';

import AppBar from '../admin-on-rest/mui/layout/AppBar';
import MuiAppBar from 'material-ui/AppBar';
import Sidebar from '../admin-on-rest/mui/layout/Sidebar';
import Menu from '../admin-on-rest/mui/layout/Menu';
import Notification from '../admin-on-rest/mui/layout/Notification';
import defaultTheme from '../admin-on-rest/mui/defaultTheme';
import { setSidebarVisibility as setSidebarVisibilityAction } from '../admin-on-rest/actions';

import storage from '../../providers/RestClient/storage';

const styles = {
    wrapper: {
        // Avoid IE bug with Flexbox, see #467
        display: 'flex',
        flexDirection: 'column',

    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',


    },
    body: {
        backgroundColor: '#edecec',
        display: 'flex',
        flex: '1 1 auto',
        overflowY: 'hidden',
        overflowX: 'scroll',
    },
    bodySmall: {
        backgroundColor: '#fff',
    },
    content: {
        flex: 1,
        padding: '2em',

    },
    contentSmall: {
        flex: 1,
        paddingTop: '3em',
    },

    loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 16,
        zIndex: 1200,
    },


};



const prefixedStyles = {};

class Login extends Component {
    static muiName = 'FlatButton';

    render() {
        return (
            <FlatButton {...this.props} containerElement={<Link to={"/login"} />} onClick={() => { storage.save('requestedLogin', true, 1e5); }} label="Ingresar" />
        );
    }
}

class Layout extends Component {
    componentWillMount() {
        if (this.props.width !== 1) {
            this.props.setSidebarVisibility(true);
        }

        if (storage.load('role') === 'readOnly') {
            //storage.clear();
        }
    }

    render() {
        const {
            children,
            dashboard,
            isLoading,
            logout,
            menu,
            theme,
            title,
            width,
        } = this.props;

        const muiTheme = getMuiTheme(theme);
        if (!prefixedStyles.main) {
            // do this once because user agent never changes
            const prefix = autoprefixer(muiTheme);
            prefixedStyles.wrapper = prefix(styles.wrapper);
            prefixedStyles.main = prefix(styles.main);
            prefixedStyles.body = prefix(styles.body);
            prefixedStyles.bodySmall = prefix(styles.bodySmall);
            prefixedStyles.content = prefix(styles.content);
            prefixedStyles.contentSmall = prefix(styles.contentSmall);
        }
        return (
            storage.load('consult') ?
                <MuiThemeProvider muiTheme={muiTheme}>
                    <div style={styles.wrapper}>
                        <div style={styles.main}>
                            {width !== 1 && <MuiAppBar title={title} showMenuIconButton={false} iconElementRight={<Login />} />}
                            <div
                                className="body"
                                style={
                                    width === 1 ? (
                                        prefixedStyles.bodySmall
                                    ) : (
                                        prefixedStyles.body
                                    )

                                }
                            >
                                <div
                                    style={
                                        width === 1 ? (
                                            prefixedStyles.contentSmall
                                        ) : (
                                            prefixedStyles.content
                                        )
                                    }
                                >
                                    {children}
                                </div>
                            </div>
                            <Notification />
                            {isLoading && (
                                <CircularProgress
                                    className="app-loader"
                                    color="#871477"
                                    size={width === 1 ? 20 : 30}
                                    thickness={2}
                                    style={styles.loader}
                                />
                            )}
                        </div>
                    </div>
                </MuiThemeProvider> : <MuiThemeProvider muiTheme={muiTheme}>
                    <div style={prefixedStyles.wrapper}>
                        <div style={prefixedStyles.main}>
                            {width !== 1 && <AppBar color="#871477" title={title} />}
                            <div
                                className="body"
                                style={
                                    width === 1 ? (
                                        prefixedStyles.bodySmall
                                    ) : (
                                        prefixedStyles.body
                                    )
                                }
                            >
                                <div
                                    style={
                                        width === 1 ? (
                                            prefixedStyles.contentSmall
                                        ) : (
                                            prefixedStyles.content
                                        )

                                    }
                                >
                                    {children}
                                </div>
                                <Sidebar theme={theme}>
                                    {createElement(menu || Menu, {
                                        logout,
                                        hasDashboard: !!dashboard,
                                    })}
                                </Sidebar>
                            </div>
                            <Notification />
                            {isLoading && (
                                <CircularProgress
                                    className="app-loader"
                                    color="#871477"
                                    size={width === 1 ? 20 : 30}
                                    thickness={2}
                                    style={styles.loader}
                                />
                            )}
                        </div>
                    </div>
                </MuiThemeProvider>
        );
    }
}

const componentPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
]);

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: componentPropType,
    isLoading: PropTypes.bool.isRequired,
    logout: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.string,
    ]),
    menu: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    setSidebarVisibility: PropTypes.func.isRequired,
    title: PropTypes.node.isRequired,
    theme: PropTypes.object.isRequired,
    width: PropTypes.number,
};

Layout.defaultProps = {
    theme: defaultTheme,
};

function mapStateToProps(state) {
    return {
        isLoading: state.admin.loading > 0,
    };
}

const enhance = compose(
    connect(mapStateToProps, {
        setSidebarVisibility: setSidebarVisibilityAction,
    }),
    withWidth()
);

export default enhance(Layout);
