import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import getDefaultValues from '../admin-on-rest/mui/form/getDefaultValues';
import FormInput from '../admin-on-rest/mui/form/FormInput';
import Toolbar from '../admin-on-rest/mui/form/Toolbar';

const formStyle = { padding: '0 1em 3em 1em' };

export class SubSimpleForm extends Component {
    handleSubmitWithRedirect = (redirect = this.props.redirect) =>
        this.props.handleSubmit(values => this.props.save(values, redirect));

    render() {
        const {
            basePath,
            children,
            invalid,
            record,
            resource,
            submitOnEnter,
            toolbar,
            version,
        } = this.props;

        return (
            <form className="simple-form">
                <div style={formStyle} key={version}>
                    {Children.map(
                        children,
                        input =>
                            input ? (
                                <FormInput
                                    basePath={basePath}
                                    input={input}
                                    record={record}
                                    resource={resource}
                                />
                            ) : null
                    )}
                </div>
                {toolbar &&
                React.cloneElement(toolbar, {
                    handleSubmitWithRedirect: this.handleSubmitWithRedirect,
                    invalid,
                    submitOnEnter,
                })}
            </form>
        );
    }
}

SubSimpleForm.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    handleSubmit: PropTypes.func, // passed by redux-form
    invalid: PropTypes.bool,
    record: PropTypes.object,
    resource: PropTypes.string,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
    submitOnEnter: PropTypes.bool,
    toolbar: PropTypes.element,
    validate: PropTypes.func,
    version: PropTypes.number,
};

SubSimpleForm.defaultProps = {
    submitOnEnter: true,
    toolbar: <Toolbar />,
};

const enhance = compose(
    connect((state, props) => ({
        initialValues: getDefaultValues(state, props),
    })),
    reduxForm({
        form: 'sub-record-form',
        enableReinitialize: true,
    })
);

export default enhance(SubSimpleForm);
