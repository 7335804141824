import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import SvgIcon from 'material-ui/SvgIcon';
import CircularProgress from 'material-ui/CircularProgress';
import { crudDuplicate as crudDuplicateAction } from '../admin-on-rest/actions/dataActions';
import translate from '../admin-on-rest/i18n/translate';
import { withRouter } from "react-router";
import store from 'store';
import { PREVENT_USER_CONFIRMATION } from "../../constants";

const FileCopy = (props) => (
    <SvgIcon {...props}>
        <path xmlns="http://www.w3.org/2000/svg" fill="none" d="M0 0h24v24H0z" />
        <path xmlns="http://www.w3.org/2000/svg" d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z" />
    </SvgIcon>
);

export class DuplicateButton extends Component {

    getBasePath() {
        const { location } = this.props;
        return location.pathname
            .split('/')
            .slice(0, -1)
            .join('/');
    }

    duplicate = (record, redirect) => {
        this.props.crudDuplicate(
            this.props.resource,
            record,
            this.getBasePath(),
            redirect
        );
    };

    handleClick = e => {

        if (this.props.saving) {
            // prevent double submission
            e.preventDefault();
        } else {
            // always submit form explicitly regardless of button type
            const { handleSubmit, redirect = 'edit' } = this.props;
            if (e) {
                e.preventDefault();
            }
            //console.log(this.props);
            handleSubmit(values => {
                store.set(PREVENT_USER_CONFIRMATION, true);
                delete values.id;
                delete values.created_at;
                delete values.updated_at;
                this.duplicate(values, redirect);
            })();
        }
    };

    render() {
        const {
            saving,
            label = 'aor.action.duplicate',
            raised = true,
            translate,
            submitOnEnter,
            redirect,
        } = this.props;
        const type = submitOnEnter ? 'submit' : 'button';
        const ButtonComponent = raised ? RaisedButton : FlatButton;
        return (
            <ButtonComponent
                buttonStyle={{ backgroundColor: "#6ABA3C" }}
                type={type}
                label={label && translate(label, { _: label })}
                icon={
                    saving && saving.redirect === redirect ? (
                        <CircularProgress size={25} thickness={2} />
                    ) : (
                        <FileCopy />
                    )
                }
                onClick={this.handleClick}
                secondary={!saving}
                style={{
                    margin: '10px 24px',
                    position: 'relative',

                }}
            />
        );
    }
}

DuplicateButton.propTypes = {
    label: PropTypes.string,
    raised: PropTypes.bool,
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    translate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    submitOnEnter: PropTypes.bool,
    handleSubmitWithRedirect: PropTypes.func,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    resource: PropTypes.string,
    crudDuplicate: PropTypes.func,
    handleSubmit: PropTypes.func
};

DuplicateButton.defaultProps = {
    handleSubmitWithRedirect: () => () => { },
};

const mapStateToProps = state => ({
    saving: state.admin.saving,
});

export default connect(mapStateToProps, { crudDuplicate: crudDuplicateAction })(translate(withRouter(DuplicateButton)));
