import React from "react";
import Edit from "../../mui/Edit";
import {TextInput, SelectInput, required, 
    SimpleForm, ReferenceInput, BooleanInput} from "../../mui/admin-on-rest";
import storage from "../../providers/RestClient/storage";

export default (props) => (
    <Edit title="Editar miembro del concejo" {...props}>
        <SimpleForm redirect="list">
            <TextInput source="nombre" label="Nombre" validate={required}/>
            <TextInput source="cargo" label="Cargo" validate={required}/>
            <SelectInput source="posicion" label="Posición" validate={required}
                choices={[
                    {id:'Quorum', name:'Quorum'},
                    {id:'Invitado', name:'Invitado'},
                    {id:'Secretaria',name:'Secretaria'}
                ]}/>
            <ReferenceInput source="concejo" label="Concejo" reference="councils" defaultValue={storage.load('COUNCIL')}
                allowEmpty validate={required}>
                <SelectInput optionText="name" options={{ disabled: !!storage.load('COUNCIL') }}/>
            </ReferenceInput>
            <BooleanInput style={{visibility:'hidden', height:'0px'}} source="activo" label="Activo" defaultValue={true}/>
        </SimpleForm>
    </Edit>
);