import React, { Component } from 'react';
import {
    DateField,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    FileInput,
    FileField,
    required,
    TextInput,
    LongTextInput,
    ListButton,
    DeleteButton,
    RefreshButton,
    SaveButton,
    Toolbar
} from '../../mui/admin-on-rest';
import Edit from '../../mui/Edit';
import ConditionalTabbedForm from '../../mui/ConditionalTabbedForm';
import ConditionalFormTab from '../../mui/ConditionalFormTab';
import { EmbeddedArrayInput } from 'aor-embedded-array';
import DefaultSelectInput from '../../mui/DefaultSelectInput';
import Comments from '../../mui/EmbeddedArrayInput';
import ConditionalReferenceInput from '../../mui/ConditionalReferenceInput';
import storage from '../../providers/RestClient/storage';
import { connect } from 'react-redux';
import { CardActions } from 'material-ui/Card';
import DuplicateButton from '../../mui/DuplicateButton';
import RichTextInput from 'aor-rich-text-input';
import { ACCEPTED_MIME_TYPES, CREATE_COUNCIL_FILTER } from "./constants";
import TinyMCEInput from '../../mui/TinyMCEInput';
import Export from "../../mui/Export";
import { ISSUE_TYPE_STUDENTS, OLD_ISSUE_TYPE_STUDENTS } from "../../constants";
import moment from "moment";
import ConditionalInput from "../../mui/ConditionalInput";
import DateTimeInput from 'aor-datetime-input';
import Divider from '@material-ui/core/Divider';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

//new styles for textfields

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    body: {
        backgroundColor: '#ffffff',
        display: 'flex',
        flex: 1,
        overflowY: 'hidden',
        overflowX: 'scroll',
    },
    content: {
        flex: 1,
        padding: '2em',
    },
    loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 16,
        zIndex: 1200,
    },

    rightObject: {
        display: 'inline-block',
        float: 'right',
        marginTop: '-13px',

    },

    horizontalMargin: {
        display: 'inline-block',
        marginRight: 32,
    },

    horizontalFecha: {
        display: 'inline-block',
        marginRight: 6
    },

    divider: {
        marginTop: '-22px',
        height: '2px',
        width: '100%',
        backgroundColor: '#871477'
    },

    horizontal: {
        display: 'inline-block',
        width: '100%',
    },

    horizontal2: {
        display: 'inline-block',

    },
    horizontalMaxWidth: {
        display: 'inline-block',
        width: '260px',

    },
    horizontalMaxWidth2: {
        display: 'inline-block',
        width: '100%',


    },

    horizontal3: {
        display: 'inline-block',
        width: '576px',
    },

    actas: {
        display: 'inline-block',
        width: '200px',
        marginRight: 32,

    },

    prueba2: {
        display: 'inline-block',
        width: '350'

    },

    prueba: {
        display: 'inline-block',
        marginRight: 32,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 10,
    },
    textField: {
        margin: "40px",
        marginLeft: "35px",
        marginRight: "10px",
        width: 250,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
};
//

const Actions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <Export basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
        <DeleteButton basePath={basePath} record={data} />
        <RefreshButton />
    </CardActions>
);


class EditIssue extends Component {
    state = {
        userId: storage.load('userId')
    };

    showConcepts = (role) => {
        if (storage.load('concejo') == 'Consejo de Instituto' && (role !== "readOnly")) {
            return (

                <ConditionalFormTab key={1} label="Comisión de estudiantes" >
                    <Comments source="comments" label="Conceptos de los Miembros" userId={this.state.userId}>
                        <ConditionalReferenceInput label="Creado por" source="createdBy" reference="clients" allowEmpty
                            filter={{ fields: { id: true, username: true, program: true } }}
                            options={{ disabled: true }}>
                            <DefaultSelectInput optionText="username" default={this.state.userId}
                                options={{ disabled: true }} />
                        </ConditionalReferenceInput>
                        <ConditionalReferenceInput label="Tipo de concepto" source="type" reference="commenttypes"
                            allowEmpty>
                            <SelectInput optionText="name" />
                        </ConditionalReferenceInput>
                        <LongTextInput source="description" label="Descripción" />
                    </Comments>
                </ConditionalFormTab>
            );
        }

        if (role !== 'admin') {
            return null;
        }

        /*return (
            [

                <ConditionalFormTab key={1} label="Conceptos coordinadores" >
                    <Comments source="comments" label="Conceptos coordinadores" userId={this.state.userId}>
                        <ConditionalReferenceInput label="Creado por" source="createdBy" reference="clients" allowEmpty
                            filter={{ fields: { id: true, username: true, program: true } }}
                            options={{ disabled: true }}>
                            <DefaultSelectInput optionText="username" default={this.state.userId}
                                options={{ disabled: true }} />
                        </ConditionalReferenceInput>
                        <ConditionalReferenceInput label="Tipo de concepto" source="type" reference="commenttypes"
                            allowEmpty>
                            <SelectInput optionText="name" />
                        </ConditionalReferenceInput>
                        <LongTextInput source="description" label="Descripción" />
                    </Comments>
                </ConditionalFormTab>
            ]
        );*/
    };

    render() {
        const { selectedCouncil, userCouncils } = this.props;

        return (
            <Edit title="Editar tema" actions={<Actions />} {...this.props}>
                {
                    permissions =>
                        <ConditionalTabbedForm toolbar={
                            <Toolbar>
                                <SaveButton />

                            </Toolbar>
                        }>
                            <ConditionalFormTab label="Información" style={styles.container}>
                                <BooleanInput style={styles.horizontalMargin} source="public" label="¿Público?" />
                                <BooleanInput style={styles.horizontalMargin} source="editable" label="¿Editable?" />
                                <DateField style={styles.rightObject} source="created_at" label="Creado en" showTime />
                                <Divider style={styles.divider} variant="inset" />
                                <ConditionalInput style={styles.horizontal}>

                                    <ReferenceInput style={styles.horizontal} label="Tipo de caso"
                                        source="type"
                                        reference="issuetypes"
                                        allowEmpty
                                    >
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                </ConditionalInput>
                                <ReferenceInput style={styles.horizontalMargin}
                                    label="resources.acts.council"
                                    source="council"
                                    reference="councils"
                                    filter={CREATE_COUNCIL_FILTER(userCouncils)}

                                    allowEmpty>
                                    <SelectInput style={styles.horizontalMargin} optionText="name" options={{ disabled: !!selectedCouncil }} />
                                </ReferenceInput>
                                {/* Parte de estado */}
                                <ReferenceInput style={styles.horizontalMargin} label="resources.acts.state" source="state"
                                    reference="issuestates" allowEmpty>
                                    <SelectInput style={styles.horizontalMargin} optionText="name" />
                                </ReferenceInput>
                                <ConditionalInput style={styles.horizontalMargin} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <ReferenceInput label="Tipo de caso de estudiante" source="student_issue_type"
                                        reference="studentissuetypes" allowEmpty>
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                </ConditionalInput>
                                {/* actas */}
                                <ReferenceInput style={styles.horizontalMargin} label="resources.acts.act" source="act" reference="acts"
                                    allowEmpty
                                    filter={{
                                        fields: { id: true, number: true, begins_at: true },
                                        where: { council: selectedCouncil, editable: true, created_at: { gte: new Date(2019, 1, 1) } },

                                    }}
                                    sort={{ field: 'created_at', order: 'DESC' }}
                                    perPage={200}
                                >
                                    <SelectInput style={styles.horizontal} optionText={record => `${record.number} - ${record.begins_at ? moment(record.begins_at).format('YYYY') : ''}`} />
                                </ReferenceInput>
                                <ReferenceInput style={styles.prueba} label="resources.acts.program" source="program"
                                    reference="programs" allowEmpty>
                                    <SelectInput style={styles.preuba} optionText="name" />
                                </ReferenceInput>

                                <ConditionalInput style={styles.horizontal2} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <ReferenceInput style={styles.horizontalMargin} label="resources.acts.center" source="center"
                                        reference="centers" allowEmpty>
                                        <SelectInput style={styles.horizontalMargin} optionText="name" />
                                    </ReferenceInput>
                                </ConditionalInput>
                                <ConditionalInput style={styles.horizontal3} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <TextInput style={styles.horizontalMargin} source="student_code" label="Código" validate={required} />
                                    <TextInput style={styles.horizontalMaxWidth} source="student_name" label="Nombre" options={{ fullWidth: true }} validate={required} />
                                </ConditionalInput>
                                <ConditionalInput style={styles.horizontal} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <TextInput style={styles.horizontalMargin} source="start_period" label="Inicio" validate={required} />
                                    <TextInput style={styles.horizontalMargin} source="level" label="Nivel" validate={required} />
                                    <TextInput style={styles.horizontalMargin} source="gpa" label="Promedio acumulado" />
                                    <TextInput style={styles.horizontalMargin} source="cc" label="Créditos cursados" />
                                    <TextInput style={styles.horizontalMargin} source="ca" label="Créditos aprobados" />
                                    <TextInput style={styles.horizontalMargin} source="email" label="correo" />
                                    <TextInput style={styles.horizontalMargin} source="cm" label="Créditos matriculados" />
                                    <DateTimeInput style={styles.horizontalFecha} source="request_date" label="Fecha de solicitud" optionsTime={{ format: 'ampm' }} />
                                    <ReferenceInput style={styles.horizontalMargin} label="Condicionalidad" source="student_conditionality"
                                        reference="studentconditionalities" allowEmpty validate={required}>
                                        <SelectInput style={styles.horizontalMargin} optionText="name" />
                                    </ReferenceInput>
                                </ConditionalInput>
                                <ConditionalInput style={styles.horizontal} condition={record => record.type === ISSUE_TYPE_STUDENTS} >
                                    <RichTextInput source="request" label="Solicitud" toolbar={[['bold']]} />
                                </ConditionalInput>

                                <TinyMCEInput source="description" label="Descripción" />
                                <FileInput
                                    source="attachments"
                                    label="Archivos de soporte"
                                    accept={ACCEPTED_MIME_TYPES}
                                    multiple>
                                    <FileField source="url" title="filename" />
                                </FileInput>
                                <ConditionalInput condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <RichTextInput source="inline_comment" label="Comentarios de Coordinadores" toolbar={[['bold']]} />
                                    <RichTextInput source="inline_feedback" label="Conceptos" toolbar={[['bold']]} />
                                </ConditionalInput>

                            </ConditionalFormTab>
                            {
                                this.showConcepts(permissions)
                            }
                        </ConditionalTabbedForm>
                }
            </Edit>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedCouncil: state.council.selected,
    userCouncils: state.council.list,
    commentTypes: state.admin.resources["commenttypes"].data
});

export default connect(mapStateToProps, null)(EditIssue);
