import spanishMessages from 'aor-language-spanish';

export default {
    'es': {
        'aor': {
            ...spanishMessages.aor,
            action: {
                ...spanishMessages.aor.action,
                add_filter: 'Buscar',
                duplicate: 'Duplicar',
                prev: 'Anterior',
            },
            input: {
                ...spanishMessages.aor.input,
                file: {
                    ...spanishMessages.aor.input.file,
                    upload_single: 'Suelte el archivo para cargar o haga click para seleccionar uno.',
                    upload_several: 'Suelte algunos archivos para cargar o haga click para seleccionar uno.'
                },
                embedded_array: {
                    add: 'Agregar',
                    remove: 'Remover'
                }
            },
            notification: {
                ...spanishMessages.aor.notification,
                duplicated: 'Tema duplicado'
            }
        },
        'resources': {
            'acts': {
                'add_issues': 'Agregar temas',
                'councils': 'Consejos',
                'council': 'Consejo',
                'type': 'Tipo',
                'state': 'Estado',
                'center': 'Sede',
                'program': 'Programa',
                'act': 'Acta',
                'issues': 'Temas'
            }
        }
    }
};
