/*
Este modulo es el encargado de descargar el documento word con el acta,
*/

import React, { Fragment } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import FileDownload from 'material-ui/svg-icons/file/file-download';
import { crudGetMany, crudGetList } from '../admin-on-rest/actions/dataActions';
import { connect } from 'react-redux';
import moment from 'moment';
import localeEs from 'moment/locale/es';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import createReport from 'docx-templates';
import { API_ENDPOINT } from "../../constants";
import storage from '../../providers/RestClient/storage';
import get from 'lodash/get';
import find from 'lodash/find';
import some from 'lodash/some';
import { ISSUE_TYPE_STUDENTS } from "../../constants";

moment.updateLocale('es', localeEs);

/**
 * A modal dialog can only be closed by selecting one of the actions.
 */
class ExportButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            exporting: false,
            miembros: [],
            council: null,
            secretaria: ""
        };
        // This binding is necessary to make `this` work in the callback
        this.exportAsWord = this.exportAsWord.bind(this);
        this.exportAsPDF = this.exportAsPDF.bind(this);
    }

    componentDidMount(){
        this.getMiembrosConcejo();
    }

    componentDidUpdate(){
        // Si el concejo cambia se actualizan todos los valores
        if(storage.load("COUNCIL") !== this.state.council){
            this.setState(state => (
                {
                    miembros: [],
                    council: storage.load("COUNCIL"),
                    secretaria: ""
                }
                )
            )
            this.getMiembrosConcejo();
            console.log(this.state.secretaria)
        }
    }

    /**
     * Se realiza una petición al backend para obtener los miembros del concejo desde
     * la base de datos.
     */
    async getMiembrosConcejo(){
        const url = `${API_ENDPOINT}/miembrosconcejo`;
        const token = storage.load("token");
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: token
        });
        
        await fetch(url, {headers})
        .then(response => response.json())
        .then(miembrosResponse => {
            let miembrosConcejo = miembrosResponse.filter(miembro => miembro['concejo'] == storage.load('COUNCIL'))
            this.setState(state => ({miembros: miembrosConcejo}))
            this.setState(state => ({secretaria: this.getSecretaria()}))
        });
    }

    /**
     * Filtra y mapea los miembros del quorum
     * @returns Los miembros del quorum mapeados
     */
    getSecretaria(){
        let secretaria = this.state.miembros.filter(miembro => miembro['posicion'] == 'Secretaria');
        secretaria = secretaria.map(q => q.nombre)[0]
        return secretaria;
    }

    getActivities(activities) {
        if (!activities) return [];

        const filtered = activities.filter(
            activity => !(activity.title.match(/[Oo]rden/) || activity.title.match(/[Qq]u[oó]rum/)));

        if (this.props.issueId) {
            return filtered
                .filter(activity => some(activity.issues, issue => issue.id === this.props.issueId))
                .map(activity => ({
                    ...activity,
                    issues: [find(activity.issues, issue => issue.id === this.props.issueId)]
                }));
        } else {
            return filtered;
        }
    }

    exportAsWord() {
        const {
            act,
            record,
            individualIssue
        } = this.props;

        const convertToNumberingScheme = (number) => {
            let baseChar = ("a").charCodeAt(0), letters = "";

            do {
                number -= 1;
                letters = String.fromCharCode(baseChar + (number % 26)) + letters;
                number = (number / 26) >> 0; // quick `floor`
            } while (number > 0);

            return letters;
        };

        const url = `${API_ENDPOINT}/acts/${individualIssue ? record.act : act.id}?includeIssues`;
        const token = storage.load("token");
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: token
        });

        fetch(url, { headers })
            .then(response => response.json())
            .then(act => {

                let invitados = act.invited.filter(inv => inv.assisted != undefined && inv.assisted);

                const actSpec = {
                    act_number: act.number,
                    act_date: act.begins_at ? moment(act.begins_at).format('DD [de] MMMM [del] YYYY') : '',
                    secretary: this.state.secretaria,
                    starts_at: act.begins_at ? moment(act.begins_at).format('hh:mm A') : '',
                    ends_at: act.ends_at ? moment(act.ends_at).format('hh:mm A') : '',
                    place: act.place,
                    año: act.begins_at ? moment(act.begins_at).format('YYYY') : '',//this.act_date.split("del")[1]
                    order_of_day: act.activities && act.activities.map((activity, index) => ({
                        starts_at: activity.begins_at ? moment(activity.begins_at).format('hh:mm A') : '',
                        ends_at: activity.ends_at ? moment(activity.ends_at).format('hh:mm A') : '',
                        index: index + 1,
                        title: activity.title,
                        done: activity.done ? '✓' : '✗',
                        hasIssues: activity.issues && activity.issues.length > 0,
                        issues: activity.issues &&
                            activity.issues.map((issue, index) => {
                                const studentIssueType = `${issue.student_issue_type ? ` - ${(this.props.studentIssueTypes[issue.student_issue_type] || {}).name}` : ''}`;
                                const center = `${issue.center ? ` ${(this.props.centers[issue.center] || {}).name}` : ''}`;

                                return ({
                                    title: issue.type === ISSUE_TYPE_STUDENTS ?
                                        `${index + 1}) ${issue.student_name}${studentIssueType}${` - ${issue.program} ${center}`}` :
                                        `${index + 1})    ${issue.title}`
                                });
                            })
                    })),
                    attended_quorum: act.quorum && act.quorum.filter(member => member.assisted).map((member, index) => ({
                        index: index + 1,
                        name: member.name,
                        charge: member.charge
                    })),
                    absent_quorum: act.quorum && act.quorum.filter(member => !member.assisted).map((member, index) => ({
                        index: index + 1,
                        name: member.name,
                        charge: member.charge
                    })),
                    invited: invitados && invitados.map((member, index) => {
                        return {
                        index: index + 1,
                        name: member.name,
                        charge: member.charge
                        }
                    }),
                    activities: this.getActivities(act.activities).map((activity, activityIndex) => ({
                        index: activityIndex + 2,
                        title: activity.title,
                        concepts: activity.feedbacks.map(feedback => ({
                            council: feedback.council ? feedback.council : '',
                            description: feedback.description ? feedback.description : ''
                        })),
                        issues: activity.issues && activity.issues.map((issue, issueIndex) => {
                            if (issue.type === ISSUE_TYPE_STUDENTS) {
                                const studentIssueType = `${issue.student_issue_type ? ` - ${(this.props.studentIssueTypes[issue.student_issue_type] || {}).name}` : ''}`;
                                const center = `${issue.center ? ` ${(this.props.centers[issue.center] || {}).name}` : ''}`;

                                return {
                                    ...issue,
                                    index: issueIndex,
                                    activityIndex,
                                    studentIssue: true,
                                    title: `${issueIndex + 1}) ${issue.student_name}${studentIssueType}${` - ${issue.program} ${center}`}`,
                                    student_conditionality: `${(this.props.studentConditionalities[issue.student_conditionality] || {}).name}`,
                                    description: issue.description ? issue.description : '',
                                    hasAttachments: issue.attachments && issue.attachments.length > 0,
                                    attachments: issue.attachments && issue.attachments.map((attachment, index) => ({
                                        index: index + 1,
                                        name: `<<${attachment.filename}>>`
                                    }))
                                }
                            }

                            return {
                                index: issueIndex,
                                activityIndex,
                                studentIssue: false,
                                title: `${convertToNumberingScheme(issueIndex + 1)})    ${issue.title}`,
                                description: issue.description ? issue.description : '',
                                hasProgram: !!issue.program,
                                program: issue.program ? issue.program : '',
                                concepts: issue.feedbacks && issue.feedbacks.map((feedback, conceptIndex) => ({
                                    index: conceptIndex,
                                    issueIndex,
                                    activityIndex,
                                    council: feedback.council ? feedback.council : '',
                                    description: feedback.description ? feedback.description : ''
                                })),
                                hasAttachments: issue.attachments && issue.attachments.length > 0,
                                attachments: issue.attachments && issue.attachments.map((attachment, index) => ({
                                    index: index + 1,
                                    name: `<<${attachment.filename}>>`
                                }))
                            };
                        })
                    }))
                };
                var templat = ''

                if (storage.load('concejo') == 'Concejo de Instituto') {
                    templat = '/templates/templateInsti.docx'
                } else {
                    templat = '/templates/template.docx'
                }

                JSZipUtils.getBinaryContent(templat, async (error, content) => {
                    if (error) {
                        this.setState({ open: false, exporting: false });
                        //eslint-disable-next-line
                        console.warn(error);
                        return;
                    }

                    try {
                        const report = await createReport({
                            template: content,
                            data: actSpec
                        });

                        const blob =
                            new Blob([report],
                                { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }
                            );
                        FileSaver.saveAs(blob, `acta ${act.number}.docx`);
                    } catch (error) {
                        //eslint-disable-next-line
                        console.warn(error);
                    }
                    this.setState({ open: false, exporting: false });
                });
            }).catch(err => {
                if (err) {
                    //eslint-disable-next-line
                    console.error(err);
                }

                this.setState({ open: false, exporting: false });
            });
    }

    exportAsPDF() {
        const {
            act
        } = this.props;

        const url = `${API_ENDPOINT}/acts/${act.id}/export`;
        const token = storage.load("token");
        const headers = new Headers({
            Accept: 'application/pdf',
            Authorization: token
        });

        fetch(url, { headers })
            .then(response => response.blob())
            .then(blob => {
                FileSaver.saveAs(blob, `acta ${act.number}.pdf`);
                this.setState({ open: false, exporting: false });
            });
    }

    render() {
        const {
            style = {}
        } = this.props;

        return (
            <Fragment>
                <FlatButton
                    hoverColor="#fcecfa"
                    rippleColor="#dc21c2"
                    primary
                    label="Exportar"
                    icon={<FileDownload />}
                    disabled={
                        this.props.disabled ||
                            this.props.individualIssue ?
                            !this.props.issueId || !this.props.record || !this.props.record.act : false
                    }
                    onClick={() => {
                        this.setState({ open: true, exporting: true });
                        this.exportAsWord();
                    }}
                    style={{ overflow: 'inherit', ...style }}
                />
                <Dialog
                    modal={false}
                    open={this.state.open}
                    onRequestClose={() => {
                        this.setState({ open: false });
                    }}
                >
                    {this.state.exporting ? "Exportando acta..." : "Seleccione en qué formato desea exportar el acta"}
                </Dialog>
            </Fragment>
        );
    }
}

function mapStateToProps(state, props) {
    const aggregate = {};

    if (props.basePath.includes('issues')) {
        aggregate.individualIssue = true;
        aggregate.issueId = get(props.record, 'id');
    }

    return {
        disabled: state.admin.loading > 0,
        act: props.actId ? state.admin.resources["acts"].data[props.actId] : props.record,
        issueStates: state.admin.resources.issuestates.data || {},
        centers: state.admin.resources.centers.data || {},
        programs: state.admin.resources.programs.data || {},
        studentIssueTypes: state.admin.resources.studentissuetypes.data || {},
        studentConditionalities: state.admin.resources.studentconditionalities.data || {},
        ...aggregate
    };
}

export default connect(mapStateToProps, { crudGetMany, crudGetList })(ExportButton)