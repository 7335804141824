import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import IssueEdit from '../IssueEdit';

import {connect} from 'react-redux';
import {submit} from 'redux-form';

import {crudUpdate} from '../admin-on-rest/actions/dataActions';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class IssueEditDialog extends Component {
    state = {
        open: false,
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.open !== this.state.open) {
            this.setState({open: nextProps.open});
        }
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        return (
            <Dialog
                fullScreen
                open={this.state.open}
                onClose={this.onClose}
                TransitionComponent={Transition}
            >
                <IssueEdit onClose={this.onClose}
                           {...this.props}/>
            </Dialog>
        )
    }
}

export default connect(state => ({}), {submit, crudUpdate})(IssueEditDialog);
