import React from 'react';
import {Toolbar as AdminToolbar} from "../admin-on-rest";
import PrevButton from "../PrevButton";
import {SaveButton} from "../admin-on-rest/mui/button";
import NextButton from "../NextButton";
import DuplicateChangeCouncilButton from '../../mui/DuplicateChangeCouncilButton';


const styles = {
    toolbar: {position: 'fixed', bottom: 0, left: 0, width: '100%', justifyContent: 'flex-end', zIndex: 2}
};



const Toolbar = ({showPrev, showNext, ...props}) => (
    <AdminToolbar style={styles.toolbar} {...props}>  
       
        <DuplicateChangeCouncilButton resource='issues'/> 
        {showPrev && showPrev() && <PrevButton redirect="prev"/>}
        <SaveButton redirect="" submitOnEnter={false}/>
        {showNext && showNext() && <NextButton redirect="next"/>}
    </AdminToolbar>
);

export default Toolbar;
