import React, { Profiler } from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    ReferenceInput,
    Filter,
    SelectInput,
    TextInput,
    EditButton,
    ShowButton,
} from '../../mui/admin-on-rest';
import { connect } from 'react-redux';
import storage from '../../providers/RestClient/storage';
import TitleOrNameField from '../../mui/TitleOrNameField';

const Filters = (props) => (
    <Filter {...props}>
        <TextInput source="search" label="Palabra clave" />

        <ReferenceInput label="Acta" source="act" reference="acts" allowEmpty
            filter={{ fields: { id: true, number: true } }}
            sort={{ field: 'number', order: 'DESC' }}
            perPage={200}>
            <SelectInput optionText="number" />
        </ReferenceInput>

        <ReferenceInput label="Programa" source="program" reference="programs" allowEmpty
            filter={{ fields: { id: true, name: true } }}
            sort={{ field: 'name', order: 'DESC' }}
        >
            <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="Sede" source="center" reference="centers" allowEmpty
            filter={{ fields: { id: true, name: true } }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Tipo" source="type" reference="issuetypes" allowEmpty
            filter={{ fields: { id: true, name: true } }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Estado" source="state" reference="issuestates" allowEmpty
            filter={{ fields: { id: true, name: true } }}
            sort={{ field: 'name', order: 'DESC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const getFilter = (props) => {
    const programas = storage.load('program');
    const role = storage.load('role');
    //console.log(props)
    // console.log(storage.load('email'))
    if (role === 'readOnly') {

        const fields = {
            attachments: false,
            feedbacks: false,
            description: false,
            ends_at: false,

        };
        //console.log(storage.load('code'))

        return {

            where: {
                email: storage.load('email')


            },
            fields
        }

    }


    const fields = {
        attachments: false,
        feedbacks: false,
        description: false,
        ends_at: false,

    };
    //


    //vamos a separar si es de concejo o de instituto
    //primer if para el caso de concejo de programas

    //if(props.selectedCouncil=='5ac2afaabe73a72aba3a7db0'){

    //si el perfil logeado tiene un programa a cargo
    if (storage.load('concejo') == 'Consejo de Instituto') {
        if (props.selectedCouncil) {
            return {

                where: {
                    council: props.selectedCouncil,

                },
                fields
            }
        } else {
            return { fields };
            // }
        }
    } else {
        switch (programas) {
            //caso de regencia y farmacia, solo tiene ese programa
            case ('5ac2af8cbe73a72aba3a7dad'):
                return {

                    where: {
                        council: props.selectedCouncil,
                        coordinador: '1'
                    },
                    fields
                }

            //para el coordinador de Tec Empresarial y Gestión Empresarial
            case '5ac2af7ebe73a72aba3a7dab': case '5bf46f4dbaad1842fb3ca5ef':


                return {

                    where: {
                        council: props.selectedCouncil,
                        coordinador: '2'

                    },
                    fields
                }



            //para el coordinador de artes: Bellas Artes y Artes plasticas
            case '5ec485deb5056b478bb221f6': case '5ac2af9bbe73a72aba3a7daf':

                return {

                    where: {
                        council: props.selectedCouncil,
                        coordinador: '3'

                    },
                    fields
                }



            //el coordinador de judicial y criminalistica
            case '5ac2af93be73a72aba3a7dae':
                return {

                    where: {
                        council: props.selectedCouncil,
                        coordinador: '4'

                    },
                    fields
                }



            //coordinador de  agroIndustrial, Tecnología Agroindustrial, Técnico Profesional en Producción Agropecuaria,Administración Agroindustrial
            case '5ac2af85be73a72aba3a7dac': case '5bf46ff6baad1842fb3ca5f1': case '5bf46fddbaad1842fb3ca5f0': case '60084a7f0f6e4f157c37d37a':

                return {

                    where: {
                        council: props.selectedCouncil,
                        coordinador: '5'

                    },
                    fields
                }


            //quedan faltando turismo, zootecnia, ing forestal, ciclobasico
            //introductorio, produccion agroind, tec agrop, todos los academicos
            //
        }
    }
    /*
    if(props.selectedCouncil&& programas){
        return {
                
            where: {
                council: props.selectedCouncil,
                
                
            },
            fields
        }
    }else{*/

    if (props.selectedCouncil) {
        return {

            where: {
                council: props.selectedCouncil,

            },
            fields
        }
    } else {
        return { fields };
        // }
    }

    //  }

};

const ActionButton = (props) => {
    const role = storage.load('role');

    if (role === 'readOnly' || role === 'commentator') {
        return <ShowButton {...props} />
    } else if (role === 'coordinator') {
        if (props.record) {
            if (props.record.editable) {
                return <EditButton {...props} />
            } else {
                return <ShowButton {...props} />
            }
        }
    } else {
        return <EditButton {...props} />
    }


    return null;
};

const IssueList = (props) => (
    // in this space is defined the header, in the consult Issues

    <List title="Temas"
        filters={<Filters />} {...props}
        filter={getFilter(props)}>

        <Datagrid>
            <TitleOrNameField label="Título" />
            <ReferenceField label="Tipo" source="type" reference="issuetypes" linkType={false} allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Tipo de caso" source="student_issue_type" reference="studentissuetypes" linkType={false} allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Estado" source="state" reference="issuestates" linkType={false} allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Programa" source="program" reference="programs" linkType={false} allowEmpty
                sort={{ field: 'id', order: 'DESC' }}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Actas" source="act" reference="acts" allowEmpty>
                <TextField source="number" />
            </ReferenceField>
            <ReferenceField label="Sede" source="center" reference="centers" linkType={false} allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created_at" label="Creado en" showTime />
            <ActionButton />
        </Datagrid>
    </List>
);

export default connect(state => ({ selectedCouncil: state.council.selected }))(IssueList)
