import React from 'react';
import { SimpleForm, TextInput, DateField, DisabledInput, required } from '../../mui/admin-on-rest';
import Edit from '../../mui/Edit';
import ColorInput from '../../mui/ColorInput';

const EditTitle = ({ record }) => {
    return <span>Editar estado de tema{record ? `: "${record.name}"` : ''}</span>;
};

export default (props) => (
    <Edit title={<EditTitle />} {...props}>
        <SimpleForm>
            <DisabledInput source="id" label="Id"/>
            <DateField source="created_at" label="Creado en" showTime/>
            <TextInput source="name" label="Nombre" validate={required}/>
            <ColorInput source="color"/>
        </SimpleForm>
    </Edit>
);
