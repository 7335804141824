import React from 'react';
import {
    List,
    Datagrid,
    DateField,
    NumberField,
    ShowButton,
    EditButton,
    TextInput,
    Filter,
    FunctionField,
    DateInput,
    FileField,
    TextField,
} from '../../mui/admin-on-rest';
import {connect} from 'react-redux'

const Filters = (props) => (
    <Filter {...props}>
        <TextInput source="postSearch" label="Palabra clave"/>
        <DateInput source="dateFilter" label="Año"
                   options={{container: 'dialog', openToYearSelection: true, mode: 'landscape'}}
                   alwaysOn
                   defaultValue={new Date(new Date().getFullYear(), 0, 1)}/>
        <TextInput source="number" label="Número de acta"/>
    </Filter>
);

const showButton = (permissions) => { //Se refiere al color de todos los botones
    if (permissions === 'readOnly' || permissions === 'commentator') {
        return <ShowButton />;
    } else if (permissions === 'coordinator') {
        return <FunctionField render={record => record.editable ?
            //Cambiar color de todos los botones
            <EditButton  basePath={'/acts'} record={record}/> : <ShowButton basePath={'/acts'} record={record}/>}/>
    } else {
        return <EditButton />
    }
};

const showEditBy = (permissions) => {
    if(permissions === 'admin' || permissions === 'secretary') {
        return <TextField source="edit_by" label="Actualizado por"/>
    }
}

const showUpdateAt = (permissions) => {
    if(permissions === 'admin' || permissions === 'secretary') {
        return <DateField source="updated_at" label="Fecha de actualización"/>
    }
}

const getFilter = (props) => {
    const fields = {
        id: true,
        number: true,
        begins_at: true,
        ends_at: true,
        edit_by: true,
        updated_at: true,
        place: true,
        editable: true,
        council: true,
        pdf: true,
        activities: true
    };

    if (props.selectedCouncil) {
        return {
            where: {
                council: props.selectedCouncil
            },
            fields
        };
    } else {
        return {
            fields
        }
    }
};

const ActList = (props) => (
    <List title="Actas"
          {...props}
          filters={<Filters/>}
          filter={getFilter(props)}
          sort={{field: 'number', order: 'DESC'}}>
        {
            permissions =>
                <Datagrid>
                    <NumberField source="number" label="Número de acta" />
                    <DateField source="begins_at" label="Fecha de inicio" showTime/>
                    <DateField source="ends_at" label="Fecha de finalización" showTime/>
                    {
                        showEditBy(permissions)
                    }
                    {
                        showUpdateAt(permissions)
                    }
                    <FileField source="pdf.url" title="pdf.filename" label="pdf"/>
                    {
                        showButton(permissions)
                    }
                </Datagrid>
        }
    </List>
);

export default connect(state => ({selectedCouncil: state.council.selected}))(ActList)
