import React, { Component } from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    ReferenceField,
    FileField,
} from '../../mui/admin-on-rest';
import { connect } from 'react-redux';
import { EmbeddedArrayField } from "aor-embedded-array";
import storage from '../../providers/RestClient/storage';
const IssueTitle = ({ record }) => (
    <span>{record ? record.title : 'Ver tema'}</span>
);

class AdminShow extends Component {
    state = {
        issueId: this.props.location.pathname.split('/').slice(2, 3).join(''),
    };

    render() {
        // console.log(storage.load('role'));
        if (storage.load('role') === 'readOnly') {
            return (
                <Show
                    title={<IssueTitle />}
                    {...this.props}
                    hasEdit={this.props.isIssueEditable(this.state.issueId)}
                >
                    <SimpleShowLayout>
                        <DateField source="created_at" label="Creado en" showTime />
                        <TextField source="title" label="Título" />
                        <ReferenceField label="resources.acts.council" source="council" reference="councils" linkType={false}
                            allowEmpty>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="resources.acts.program" source="program" reference="programs" linkType={false}
                            allowEmpty>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="resources.acts.center" source="center" reference="centers" linkType={false}
                            allowEmpty>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Consejo" source="council" reference="councils" linkType={false}
                            allowEmpty>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="inline_feedback" label="Título" />
                    </SimpleShowLayout>
                </Show>
            );
        }
        return (
            <Show
                title={<IssueTitle />}
                {...this.props}
                hasEdit={this.props.isIssueEditable(this.state.issueId)}
            >
                <SimpleShowLayout>
                    <DateField source="created_at" label="Creado en" showTime />
                    <TextField source="title" label="Título" />
                    <ReferenceField label="resources.acts.state" source="state" reference="issuestates" linkType={false}
                        allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="resources.acts.type" source="type" reference="issuetypes" linkType={false} allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="resources.acts.council" source="council" reference="councils" linkType={false}
                        allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="resources.acts.program" source="program" reference="programs" linkType={false}
                        allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="resources.acts.center" source="center" reference="centers" linkType={false}
                        allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <RichTextField source="description" label="Descripción" />
                    <FileField source="attachments" src="url" title="filename" />
                    <EmbeddedArrayField source="feedbacks" label="Conceptos">
                        <ReferenceField label="Consejo" source="council" reference="councils" linkType={false}
                            allowEmpty>
                            <TextField source="name" />
                        </ReferenceField>
                        <RichTextField source="description" label="Descripción" />
                    </EmbeddedArrayField>
                </SimpleShowLayout>
            </Show>
        );
    }
}

const mapStateToProps = (state) => ({
    location: state.routing.location,
    isIssueEditable: (issueId) =>
        state.admin.resources.issues.data[issueId] &&
        state.admin.resources.issues.data[issueId].editable,
});

export default connect(mapStateToProps)(AdminShow);

