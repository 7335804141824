export default {
    tabs: {
        backgroundColor: 'white',
        selectedTextColor: '#871477',
        textColor: '#757575',
    },
    inkBar: {
        backgroundColor: '#871477',
    },
};
