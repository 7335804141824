import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import FormField from '../admin-on-rest/mui/form/FormField';

const ConditionalInput = ({condition, formValues, children, ...rest}) => {
    if (condition(formValues)) {
        if (Array.isArray(children)) {
            return (
                <div>
                    {React.Children.map(children, child => (
                        <div
                            key={child.props.source}
                            style={child.props.style}
                            className={`aor-input-${child.props.source}`}
                        >
                            <FormField input={child} {...rest} />
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div key={children.props.source} style={children.props.style} className={`aor-input-${children.props.source}`}>
                <FormField input={children} {...rest} />
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state, props) => ({formValues: getFormValues(props.form || 'record-form')(state)});

const ConnectedConditionalInput = connect(mapStateToProps)(ConditionalInput);

ConnectedConditionalInput.propTypes = {
    condition: PropTypes.func
};

ConnectedConditionalInput.defaultProps = {
    condition: () => true
};

export default ConnectedConditionalInput;

