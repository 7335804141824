import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    FunctionField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from '../../mui/admin-on-rest';

const roleMap = {
    'admin': 'Administrador',
    'readOnly': 'Solo lectura',
    'commentator': 'Miembro',
    'secretary': 'Secretaria',
    'coordinator': 'Miembro Presidente'
};

const mapRole = (role) => role.name in roleMap ? roleMap[role.name] : role.name;

export default (props) => (
    <List title="Usuarios" {...props}
        filter={{ fields: { id: true, email: true, username: true, roles: true, program: true, councils: true } }}>
        <Datagrid>
            <TextField source="username" label="Nombre" />
            <TextField source="email" label="Correo" />
            <FunctionField source="roles" label="Roles" render={record => record.roles.map(mapRole).join(',')} />
            <ReferenceField label="Programa" source="program" reference="programs" linkType={false} allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField label="Consejos" source="councils" reference="councils" linkType={false} allowEmpty>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton />
        </Datagrid>
    </List>
);
