import {queryParameters, fetchJson} from './fetch';
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
} from './types';
import cloneDeep from 'lodash/cloneDeep';

import storage from './storage';

export * from './authClient';

/**
 * Maps admin-on-rest queries to a loopback powered REST API
 *
 * @see https://github.com/strongloop/loopback
 * @example
 * GET_LIST     => GET http://my.api.url/posts?filter[sort]="title ASC"&filter[skip]=0&filter[limit]=20
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter[where][or]=[{id:123},{id:456}]
 * UPDATE       => PATCH http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchJson) => {

    const ASSETS_URL = `${apiUrl}/assets/upload`;

    const uploadFile = file => new Promise((resolve, reject) => {
        const body = new FormData();
        body.append('file', file.rawFile);
        body.append('name', file.rawFile.name);

        fetch(ASSETS_URL, {
            method: 'POST',
            body
        }).then(response => response.json()).then(
            json => resolve({filename: json.filename, url: json.url})
        ).catch(error => reject(error));
    });

    const processFilter = (filter) => {
        const query = {};

        if (filter.dateFilter) {
            query['where'] = {'created_at': {gte: filter.dateFilter}};
            delete filter.dateFilter;
        }

        if (filter.search) {
            query['where'] =
                {...query['where'], $text: {search: filter.search}};
            delete filter.search;
        }

        /*
        if (filter.postSearch) {
            query['postWhere'] = {
                search: filter.postSearch
            };
            delete filter.postSearch;
        }
         */

        return {...query['where'], ...filter};
    };

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        const finalResource =
            resource === '_acts' || resource === '_issues' ?
                '' + resource.split('_')[1].toLowerCase() :
                '' + resource.toLowerCase();

        let url = '';
        const options = {};
        switch (type) {
            case GET_LIST: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;

                const query = {};

                if (params.filter) {
                    if (params.filter.fields) {
                        query.fields = params.filter.fields;
                        delete params.filter.fields;
                    }

                    if (params.filter.order) {
                        query.order = params.filter.order;
                        delete params.filter.order;
                    }

                    if (params.filter.where) {
                        const where = params.filter.where;

                        if (params.filter.postSearch) {
                            query['postWhere'] = {
                                search: params.filter.postSearch
                            };
                            delete params.filter.postSearch;
                        }

                        delete params.filter.where;
                        query['where'] = { ...where, ...processFilter(params.filter) };
                    } else {
                        //Act date filter
                        if (params.filter.dateFilter) {
                            query['where'] = {'created_at': {gte: params.filter.dateFilter}};
                            delete params.filter.dateFilter;
                        }

                        if (params.filter.search) {
                            query['where'] =
                                {...query['where'], $text: {search: params.filter.search}};
                            delete params.filter.search;
                        }

                        if (params.filter.postSearch) {
                            query['postWhere'] = {
                                search: params.filter.postSearch
                            };
                            delete params.filter.postSearch;
                        }

                        query['where'] = {...query['where'], ...params.filter};
                    }

                    if (field && !query['where'].hasOwnProperty('$text'))  {
                        query['order'] = [field + ' ' + order];
                    } else {
                        query['options'] = {disableDefaultSort: true};
                    }

                    if (perPage > 0) {
                        query['limit'] = perPage;
                        if (page >= 0) {
                            query['skip'] = (page - 1) * perPage;
                        }
                    }
                }

                url = `${apiUrl}/${finalResource}?${queryParameters({filter: JSON.stringify(query)})}`;
                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${finalResource}/${params.id}`;
                break;
            case GET_MANY: {
                const listId = params.ids.map(id => {
                    return {'id': id};
                });
                const query = {
                    'where': {'or': listId}
                };

                url = `${apiUrl}/${finalResource}?${queryParameters({filter: JSON.stringify(query)})}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {...params.filter};
                query['where'] = {};
                query['where'][params.target] = params.id;
                if (field) query['order'] = [field + ' ' + order];
                if (perPage > 0) {
                    query['limit'] = perPage;
                    if (page >= 0) {
                        query['skip'] = (page - 1) * perPage;
                    }
                }
                url = `${apiUrl}/${finalResource}?${queryParameters({filter: JSON.stringify(query)})}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${finalResource}/${params.id}`;
                options.method = 'PATCH';

                params.data['edit_by'] = getUserAuditoria();

                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${finalResource}`;
                options.method = 'POST';

                params.data['edit_by'] = getUserAuditoria();
                
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${finalResource}/${params.id}`;
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const {headers, json} = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!headers.has('x-total-count')) {
                    throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
                }
                return {
                    data: json,
                    total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
                };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            default:
                return { data: json };
        }
    };

    const getUserAuditoria = () => {
        return storage.load('userName');
    }

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {
        type = cloneDeep(type);
        resource = cloneDeep(resource);
        params = cloneDeep(params);

        if (type === GET_MANY && params && params.ids && params.ids.length <= 0) {
            return Promise.resolve({data: []});
        }

        if ((type === UPDATE || type === CREATE) && resource === 'acts') {
            if (params.data.pdf) {
                params = {
                    ...params,
                    data: {
                        ...params.data,
                        pdf: params.data.pdf.length <= 0 ? null : params.data.pdf[0]
                    }
                }
            }
        }

        if ((type === UPDATE || type === CREATE) &&
            resource === 'acts' &&
            params.data.pdf &&
            params.data.pdf.rawFile instanceof File) {
            return uploadFile(params.data.pdf).then(uploadedPdf => {
                const newParams = {
                    ...params,
                    data: {
                        ...params.data,
                        pdf: uploadedPdf
                    }
                };

                const {url, options} = convertRESTRequestToHTTP(type, resource, newParams);
                return httpClient(url, options)
                    .then(response => convertHTTPResponseToREST(response, type, resource, newParams));
            });
        }

        if ((type === UPDATE || type === CREATE)
            && (resource === 'issues' || resource === '_issues')
            && params.data.attachments
            && params.data.attachments.length
        ) {
            const formerFiles = params.data.attachments.filter(p => !(p.rawFile instanceof File));
            const newFiles = params.data.attachments.filter(p => p.rawFile instanceof File);

            return Promise.all(newFiles.map(uploadFile))
                .then((uploadedFiles) => {
                    const newParams = {
                        ...params,
                        data: {
                            ...params.data,
                            attachments: [...uploadedFiles, ...formerFiles]
                        }
                    };

                    const {url, options} = convertRESTRequestToHTTP(type, resource, newParams);
                    return Promise.all([Promise.resolve(uploadedFiles), httpClient(url, options)]);
                })
                .then((responses) => {
                    const newParams = {
                        ...params,
                        data: {
                            ...params.data,
                            attachments: [...responses[0], ...formerFiles]
                        }
                    };
                    return convertHTTPResponseToREST(responses[1], type, resource, newParams)
                });
        } else {
            const {url, options} = convertRESTRequestToHTTP(type, resource, params);
            return httpClient(url, options)
                .then(response => {
                    if ((type === GET_ONE) && resource === 'clients') {
                        return {
                            ...response,
                            json: {
                                ...response.json,
                                role: response.json.roles ? response.json.roles[0].id : undefined
                            }
                        };
                    } else {
                        return response;
                    }
                })
                .then(response => convertHTTPResponseToREST(response, type, resource, params));
        }
    };
};
