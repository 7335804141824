import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import withIssueData from '../withIssueData';
import IssueEditDialog from '../IssueEditDialog';

import get from 'lodash.get';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';

import Chip from 'material-ui/Chip';
import ChipInput from 'material-ui-chip-input';

import translate from '../admin-on-rest/i18n/translate';
import FieldTitle from '../admin-on-rest/util/FieldTitle';
import { blue300 } from "material-ui/styles/colors";

const dataSourceConfig = { text: 'text', value: 'value' };

const svgStyle = {
    paddingLeft: 5,
    paddingRight: 5
};

const labelStyle = {
    fontSize: 12
};

/**
 * An Input component for an array
 *
 * @example
 * <SelectIssuesInput source="categories" />
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: '1', name: 'Book' },
 *    { id: '2', name: 'Video' },
 *    { id: '3', name: 'Audio' },
 * ];
 * <SelectIssuesInput source="categories" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: '1', name: 'Book', plural_name: 'Books' },
 *    { _id: '2', name: 'Video', plural_name: 'Videos' },
 *    { _id: '3', name: 'Audio', plural_name: 'Audios' },
 * ];
 * <SelectIssuesInput source="categories" choices={choices} optionText="plural_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: '1', name: 'Book', quantity: 23 },
 *    { id: '2', name: 'Video', quantity: 56 },
 *    { id: '3', name: 'Audio', quantity: 12 },
 * ];
 * const optionRenderer = choice => `${choice.name} (${choice.quantity})`;
 * <SelectIssuesInput source="categories" choices={choices} optionText={optionRenderer} />
 *
 * The object passed as `options` props is passed to the material-ui-chip-input component
 * @see https://github.com/TeamWertarbyte/material-ui-chip-input
 */
export class SelectIssuesInput extends Component {
    state = {
        values: [],
        index: -1,
        value: '',
        open: false
    };

    componentWillMount = () => {
        this.setState({
            values: this.getChoicesForValues(
                this.props.input.value || [],
                this.props.choices
            ),
        });
    };

    componentWillReceiveProps = nextProps => {
        if (
            this.props.choices !== nextProps.choices ||
            this.props.input.value !== nextProps.input.value
        ) {
            this.setState({
                values: this.getChoicesForValues(
                    nextProps.input.value || [],
                    nextProps.choices
                ),
            });
        }
    };

    componentWillUnmount() {
        console.log('SelectIssueInput unmounted');
    }

    handleBlur = () => { };

    handleFocus = () => {
        const extracted = this.extractIds(this.state.values);
        this.props.onFocus(extracted);
        this.props.input.onFocus(extracted);
    };

    handleAdd = newValue => {
        const values = [...this.state.values, newValue];
        this.setState({ values }, () => this.handleChange(this.state.values));
    };

    handleDelete = newValue => {
        const values = this.state.values.filter(v => v.value !== newValue);
        this.setState({ values }, () => this.handleChange(this.state.values));
    };

    handleChange = eventOrValue => {
        const extracted = this.extractIds(eventOrValue);
        this.props.onChange(extracted);
        this.props.input.onChange(extracted);
    };

    extractIds = eventOrValue => {
        const value =
            eventOrValue.target && eventOrValue.target.value
                ? eventOrValue.target.value
                : eventOrValue;
        if (Array.isArray(value)) {
            return value.map(o => o.value);
        }
        return [value];
    };

    getChoicesForValues = (values, choices = []) => {
        const { optionValue, optionText } = this.props;
        if (!values || !Array.isArray(values)) {
            throw Error('Value of SelectIssuesInput should be an array');
        }
        return values
            .map(
                value =>
                    choices.find(c => c[optionValue] === value) || {
                        [optionValue]: value,
                        [optionText]: value,
                    }
            )
            .map(this.formatChoice);
    };

    formatChoices = choices => choices.map(this.formatChoice);

    formatChoice = choice => {
        const {
            optionText,
            optionValue,
            translateChoice,
            translate,
            studentIssueTypes,
            programs,
            centers
        } = this.props;
        const choiceText =
            typeof optionText === 'function'
                ? optionText(choice, { studentIssueTypes, programs, centers })
                : get(choice, optionText);
        return {
            value: get(choice, optionValue),

            text: translateChoice
                ? translate(choiceText, { _: choiceText })
                : choiceText,
            state: choice.state && this.props.issueStates && this.props.issueStates[choice.state]
        };
    };

    onSaveIssue = (id, basePath, record, redirect) => {
        if (redirect === 'next' && this.state.values.length > 1 && this.state.index < this.state.values.length - 1) {
            this.setState(state => ({ index: state.index + 1, value: state.values[state.index + 1].value }));
        } else if (redirect === 'prev' && this.state.values.length > 1 && this.state.index > 0) {
            this.setState(state => ({ index: state.index - 1, value: state.values[state.index - 1].value }));
        } else {
            this.setState({ open: false });
        }

        this.props.crudGetOneSuccess('_issues', id, basePath, record);
    };

    renderIssueStates = (groupedIssueStates) => (
        <div>
            {
                Object.keys(groupedIssueStates).map((key, index) => (
                    <Fragment key={index}>
                        <svg style={svgStyle} height="10" width="10">
                            <circle cx="5" cy="5" r="5" fill={key} />
                        </svg>
                        <label style={labelStyle}>
                            {groupedIssueStates[key].map(issueState => issueState.name).join(', ')}
                        </label>
                    </Fragment>
                ))
            }
        </div>
    );

    renderChip = ({ value, text, isFocused, isDisabled, handleRequestDelete, defaultStyle, chip }, key) => (

        < Chip
            key={6}
            style={{ ...defaultStyle, pointerEvents: isDisabled ? 'none' : undefined, alignItems: 'center' }}
            labelStyle={{ whiteSpace: 'normal', lineHeight: 'normal', paddingTop: '8px', paddingBottom: '8px' }}
            backgroundColor={isFocused ? blue300 : null}
            deleteIconStyle={{ minWidth: '24px', margin: '0px 4px 0px -8px' }}
            onRequestDelete={this.props.hasDelete ? handleRequestDelete : null}
            onClick={(event) => {
                event.stopPropagation();
                this.setState({ open: true, index: key, value });
            }}
        >
            <span style={{ fontSize: 10 }}>{key + 1})</span> {text}
            {
                chip.state ?
                    <svg style={{ paddingLeft: 5 }} height="10" width="10">
                        <circle cx="5" cy="5" r="5" fill={chip.state.color} />
                        ({chip.state.name})
                    </svg> : null
            }
        </Chip >
    );

    render() {
        const {
            elStyle,
            input,
            isRequired,
            choices,
            label,
            meta,
            options,
            resource,
            source,
            setFilter,
            issueStates,
            activities = [],
            record
        } = this.props;

        if (typeof meta === 'undefined') {
            throw new Error(
                "The SelectIssuesInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/admin-on-rest/Inputs.html#writing-your-own-input-component for details."
            );
        }
        const { touched, error } = meta;

        const groupedIssueStates =
            groupBy(Object.keys(issueStates || {}).map(key => issueStates[key]), 'color');

        const otherSelectedIssues =
            flatten(activities.map(activity => activity.issues || []));

        let values = this.state.values.sort((a, b) => {
            let aa = a.text.split('- ')[1];
            let bb = b.text.split('- ')[1];

            if (aa < bb) return -1
            else if (aa > bb) return 1
            return 0
        });



        return (
            <Fragment>
                <ChipInput
                    {...input}
                    value={values}
                    // Override onBlur so that redux-form does not try to handle it and miss
                    // updates from onRequestAdd
                    chipRenderer={this.renderChip}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    onClick={this.handleFocus}
                    onRequestAdd={this.handleAdd}
                    onRequestDelete={this.handleDelete}
                    onUpdateInput={setFilter}
                    floatingLabelText={
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    }
                    errorText={touched && error}
                    style={elStyle}
                    dataSource={this.formatChoices(choices.filter(choice => !otherSelectedIssues.includes(choice.id)))}
                    dataSourceConfig={dataSourceConfig}
                    openOnFocus
                    {...options}
                />

                {
                    Object.keys(groupedIssueStates).length > 0 && this.renderIssueStates(groupedIssueStates)
                }
                <IssueEditDialog
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                    onSave={this.onSaveIssue}
                    actId={record.id}
                    issueIndex={this.state.index}
                    issueId={this.state.value}
                    issues={this.state.values.map(e => e.value)}
                    issueStates={issueStates}
                />
            </Fragment>
        );
    }
}

SelectIssuesInput.propTypes = {
    addField: PropTypes.bool.isRequired,
    elStyle: PropTypes.object,
    choices: PropTypes.arrayOf(PropTypes.object),
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    setFilter: PropTypes.func,
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
    optionValue: PropTypes.string.isRequired,
    resource: PropTypes.string,
    source: PropTypes.string,
    translate: PropTypes.func.isRequired,
    translateChoice: PropTypes.bool.isRequired,
};

SelectIssuesInput.defaultProps = {
    addField: true,
    choices: [],
    onBlur: () => true,
    onChange: () => true,
    onFocus: () => true,
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
};

export default withIssueData(translate(SelectIssuesInput));
