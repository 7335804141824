import React from 'react';
import { Create, SimpleForm, TextInput, required } from '../../mui/admin-on-rest';
import ColorInput from '../../mui/ColorInput';

export default (props) => (
    <Create title="Nuevo Estado de Tema" {...props}>
        <SimpleForm>
            <TextInput source="name" label="Nombre" validate={required}/>
            <ColorInput source="color"/>
        </SimpleForm>
    </Create>
);
