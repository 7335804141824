import React from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, EditButton } from '../../mui/admin-on-rest';

export default (internalProps) => (props) => (
    <List title={internalProps.plural} {...props}>
        <Datagrid>
            <ReferenceField label="Nombre" source="id" reference={internalProps.reference} linkType={false}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="created_at" label="Creado en" showTime/>
            <DateField source="updated_at" label="Editado en" showTime/>
            <EditButton />
        </Datagrid>
    </List>
);
