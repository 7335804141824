import React, { Component } from 'react';
import {
    Create as AdminCreate,
    TabbedForm,
    FormTab,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    SelectInput,
    LongTextInput,
    required, FileField, FileInput
} from '../../mui/admin-on-rest';
import { EmbeddedArrayInput } from 'aor-embedded-array';
import CarouselEmbeddedArrayInput from '../../mui/CarouselEmbeddedArrayInput';
import DateTimeInput from 'aor-datetime-input';
import { connect } from 'react-redux';
import storage from "../../providers/RestClient/storage";
import defaultValuesProg from './defaultValues';
import defaultValuesInsti from './defaultInsti'
import { API_ENDPOINT } from "../../constants";

/**
 * Maneja valores por defecto como el concejo (council), el lugar de la reunión (place)
 * y las actividades a realizar (activities)
 * */  
let defaultValues = {}

class Create extends Component {

    constructor(props){
        super(props);

        // Se inicializa el Estado del componente.
        this.state = {
            miembros: [],
            council: storage.load("COUNCIL"),
            quorum: [],
            invitados: [],
            userName: storage.load('userName'),
        }
    }

    componentDidMount(){
        this.definirDefaultValues();
        this.getMiembrosConcejo();
    }

    componentDidUpdate(prevProps){
        // Si el concejo cambia se actualizan todos los valores
        if(this.props.selectedCouncil !== prevProps.selectedCouncil){
            this.definirDefaultValues();
            this.setState(state => (
                {
                    miembros: [],
                    council: storage.load("COUNCIL"),
                    quorum: [],
                    invitados: []
                }
                )
            )
            this.getMiembrosConcejo();
        }
    }

    /**
     * Se definen los valores por defecto del acta.
     */
    definirDefaultValues(){
        if (storage.load('COUNCIL') == defaultValuesInsti.council) {
            defaultValues = defaultValuesInsti
        } else {
            defaultValues = defaultValuesProg
        }
    }

    /**
     * Se realiza una petición al backend para obtener los miembros del concejo desde
     * la base de datos.
     */
    async getMiembrosConcejo(){
        const url = `${API_ENDPOINT}/miembrosconcejo`;
        const token = storage.load("token");
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: token
        });

        await fetch(url, {headers})
        .then(response => response.json())
        .then(miembrosResponse => {
            let miembrosConcejo = miembrosResponse.filter(miembro => miembro['concejo'] == storage.load('COUNCIL'))
            this.setState(state => ({miembros: miembrosConcejo}))
            this.setState(state => ({quorum: this.getQuorum()}))
            this.setState(state => ({invitados: this.getInvitados()}))
        });
    }

    /**
     * Filtra y mapea los miembros del quorum
     * @returns Los miembros del quorum mapeados
     */
    getQuorum(){
        let quorum = this.state.miembros.filter(miembro => miembro['posicion'] == 'Quorum');
        quorum = quorum.map(q => ({"name":q.nombre, "charge":q.cargo}))
        return quorum;
    }

    /**
     * Filtra y mapea los miembros invitados
     * @returns Los miembros invitados mapeados
     */
    getInvitados(){
        let invitados = this.state.miembros.filter(miembro => miembro['posicion'] == 'Invitado');
        invitados = invitados.map(q => ({"name":q.nombre, "charge":q.cargo}))
        return invitados;
    }

    render() {

        return (
            <AdminCreate title="Nueva acta" {...this.props}>
                <TabbedForm redirect="edit">
                    <FormTab label="Información básica">
                        <TextInput source="number" label="Número de acta" validate={required} />
                        <DateTimeInput source="begins_at" label="Fecha de inicio" optionsTime={{ format: 'ampm' }} />
                        <DateTimeInput source="ends_at" label="Fecha de finalización" optionsTime={{ format: 'ampm' }} />
                        <ReferenceInput
                            label="resources.acts.council"
                            source="council"
                            reference="councils"
                            allowEmpty
                            validate={required}
                            defaultValue={this.props.selectedCouncil ? this.props.selectedCouncil : defaultValues.council}
                        >
                            <SelectInput optionText="name" options={{ disabled: !!this.props.selectedCouncil }} />
                        </ReferenceInput>
                        <TextInput source="place" label="Sala de Juntas" defaultValue={defaultValues.place} />
                        <FileInput
                            source="pdf"
                            label="Acta escaneada"
                            accept="application/pdf">
                            <FileField source="url" title="filename" />
                        </FileInput>
                    </FormTab>
                    <FormTab label="Quorum">
                        <EmbeddedArrayInput source="quorum" label="Quorum" defaultValue={this.state.quorum}>
                            <TextInput source="name" label="Nombre" validate={required} />
                            <TextInput source="charge" label="Cargo" />
                        </EmbeddedArrayInput>
                    </FormTab>
                    <FormTab label="Invitados">
                        <EmbeddedArrayInput source="invited" label="Invitados" defaultValue={this.state.invitados}>
                            <TextInput source="name" label="Nombre" validate={required} />
                            <TextInput source="charge" label="Cargo" />
                            <LongTextInput source="justification" label="Justificación" />
                        </EmbeddedArrayInput>
                    </FormTab>
                    <FormTab label="Actividades">
                        <CarouselEmbeddedArrayInput
                            source="activities"
                            label="Actividades"
                            allowAdd={false}
                            allowRemove={false}
                            defaultValue={defaultValues.activities}>
                            <TextInput source="title" label="Título" validate={required} />
                            <ReferenceArrayInput label="resources.acts.issues" source="issues" reference="issues" allowEmpty
                                filter={{ fields: { id: true, title: true } }} perPage={200}>
                                <SelectArrayInput optionText="title" />
                            </ReferenceArrayInput>
                        </CarouselEmbeddedArrayInput>
                    </FormTab>
                </TabbedForm>
            </AdminCreate>
        );
    }
}

export default connect(state => ({ selectedCouncil: state.council.selected }))(Create);
