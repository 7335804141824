import React, { Component } from 'react';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import {FieldTitle} from '../admin-on-rest';

const sanitizeRestProps = ({
                               alwaysOn,
                               basePath,
                               component,
                               defaultValue,
                               formClassName,
                               initializeForm,
                               input,
                               isRequired,
                               label,
                               locale,
                               meta,
                               options,
                               optionText,
                               optionValue,
                               record,
                               resource,
                               allowEmpty,
                               source,
                               textAlign,
                               translate,
                               translateChoice,
                               toolbar,
                               menubar,
                               plugins,
                               ...rest
                           }) => rest;

class TinyMCEInput extends Component {
    handleBlur(eventOrValue) {
        this.props.onBlur(eventOrValue);
        this.props.input.onBlur(eventOrValue);
    }

    handleFocus(event) {
        this.props.onFocus(event);
        this.props.input.onFocus(event);
    }

    handleChange(eventOrValue) {
        this.props.onChange(eventOrValue);
        this.props.input.onChange(eventOrValue.target.getContent());
    }

    handleEditorChange(content, editor) {
        this.props.input.onChange(content);
    }

    render() {
        const {
            className,
            input,
            isRequired,
            label,
            meta = {},
            options,
            resource,
            source,
            type,
            config,
            plugins,
            toolbar,
            menubar,
            ...rest
        } = this.props;

        if (typeof meta === 'undefined') {
            throw new Error(
                "The TinyMCEInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
            );
        }

        const { touched, error } = meta;
        const value = input.value || "";

        return <TinyMCE
            margin="normal"
            type={type}
            label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
            error={!!(touched && error)}
            helperText={touched && error}
            className={className}
            init={{
                plugins,
                menubar,
                height: 400,
                toolbar,
                paste_data_images: true,
                paste_enable_default_filters: false,
                ...options
            }}
            initialValue={value}
            value={input.value}
            onEditorChange={(content, editor) => this.handleEditorChange(content, editor)}
            {...options}
            {...input}
            {...sanitizeRestProps(rest)}
            onBlur={event=>this.handleBlur(event)}
            onFocus={event=>this.handleFocus(event)}
            onChange={event=>this.handleChange(event)}
        />;
    }
}

TinyMCEInput.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    source: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

TinyMCEInput.defaultProps = {
    addField: true,
    addLabel: true,
    record: {},
};

export default TinyMCEInput;
