import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Create,
    SimpleForm,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    FileInput,
    FileField,
    required,
    LongTextInput, TextInput
} from '../../mui/admin-on-rest';
import RichTextInput from 'aor-rich-text-input';
import ConditionalInput from '../../mui/ConditionalInput';
import {connect} from 'react-redux';
import TinyMCEInput from '../../mui/TinyMCEInput';
import {ISSUE_TYPE_STUDENTS, OLD_ISSUE_TYPE_STUDENTS} from "../../constants";
import {ACCEPTED_MIME_TYPES, CREATE_COUNCIL_FILTER} from "./constants";
import moment from 'moment';
import DateTimeInput from 'aor-datetime-input';
import Divider from '@material-ui/core/Divider';

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    body: {
        backgroundColor: '#ffffff',
        display: 'flex',
        flex: 1,
        overflowY: 'hidden',
        overflowX: 'scroll',
    },
    content: {
        flex: 1,
        padding: '2em',
    },
    loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 16,
        zIndex: 1200,
    },

    rightObject: {
        display: 'inline-block',
        float: 'right',
        marginTop: '-3px',

    },

    horizontalMargin: { 
        display: 'inline-block', 
        marginRight: 32
    },

    horizontalFecha: { 
        display: 'inline-block', 
        marginRight: 6
    },

    divider: {
        height: '2px',
        backgroundColor: '#871477'
    },

    horizontal:{
        display: 'inline-block',
        width: '100%',
    },

    horizontal2:{
        display: 'inline-block',
            
    },
    horizontalMaxWidth:{
        display: 'inline-block',
        width: '260px',
        
    },
    horizontalMaxWidth2:{
        display: 'inline-block',
        width: '100%',
        
    },

    horizontal3:{
        display: 'inline-block',
        width: '576px',
    },

    horizontal4:{
        display: 'inline-block',
        marginTop: '-28px'
    },


    actas: {
        display: 'inline-block',
        width: '200px',
        marginRight: 32,

    },

    prueba2:{
     display: 'inline-block',
     width: '350'

    },

    prueba:{
        display: 'inline-block',
        marginRight: 32,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 10,
      },
      textField: {
        margin:"40px",
        marginLeft: "35px",
        marginRight: "10px",
        width: 250,
      },
      dense: {
        marginTop: 19,
      },
      menu: {
        width: 200,
      },
};

class CreateIssue extends Component {
    render() {
        const {
            selectedCouncil,
            userCouncils
        } = this.props;

        return (
            <Create title="Nuevo tema" {...this.props}>
                <SimpleForm redirect="list">
                    <ReferenceInput style = {styles.horizontal4}
                        label="Tipo de caso"
                        source="type"
                        reference="issuetypes"
                        filter={{where: {id: {neq: OLD_ISSUE_TYPE_STUDENTS}}}}
                        allowEmpty>
                        <SelectInput style = {styles.horizontal4} optionText="name"/>
                    </ReferenceInput>
                    <BooleanInput style = {styles.rightObject} source="public" label="¿Público?"/>
                    <Divider style = {styles.divider} variant="inset"/>

                    <ConditionalInput condition={record => record.type !== ISSUE_TYPE_STUDENTS}>
                        <LongTextInput source="title" label="Título" validate={required}/>
                    </ConditionalInput>
                    <ReferenceInput style = {styles.horizontalMargin}
                        label="resources.acts.council"
                        source="council"
                        reference="councils"
                        filter={CREATE_COUNCIL_FILTER(userCouncils)}
                        defaultValue={selectedCouncil ? selectedCouncil : undefined}
                        allowEmpty>
                        <SelectInput style = {styles.horizontalMargin} optionText="name" options={{disabled: !!selectedCouncil}}/>
                    </ReferenceInput>
                    <ReferenceInput style = {styles.horizontalMargin} label="resources.acts.state" source="state"
                                    reference="issuestates" allowEmpty
                                    defaultValue={"5ce30b0b2467720f9c3647c2"}>
                        <SelectInput style = {styles.horizontalMargin} optionText="name" options={{disabled: !!'5ce30b0b2467720f9c3647c2'}}/>
                    </ReferenceInput>
                    <ConditionalInput style = {styles.horizontalMargin} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                        <ReferenceInput label="Tipo de caso de estudiante" source="student_issue_type"
                                        reference="studentissuetypes" allowEmpty>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                    </ConditionalInput>
                    <ReferenceInput style = {styles.horizontalMargin} label="resources.acts.act" source="act" reference="acts"
                                    allowEmpty
                                    filter={{
                                        fields: {id: true, number: true, begins_at: true},
                                        where: {council:selectedCouncil,created_at:{gte:new Date(2019,1,1)},editable:true},
                                    }}
                                    sort={{field: 'created_at', order: 'DESC'}}
                                    perPage={200}
                    >
                        <SelectInput style = {styles.horizontal} optionText={record => `${record.number} - ${record.begins_at ? moment(record.begins_at).format('YYYY') : ''}`}/>
                    </ReferenceInput>

                    <ReferenceInput style = {styles.horizontalMargin} label="resources.acts.program" source="program"
                                    reference="programs" allowEmpty>
                        <SelectInput style = {styles.horizontalMargin} optionText="name" />
                        
                    </ReferenceInput>
                    
                   
                    <ReferenceInput style = {styles.horizontalMargin} label="resources.acts.center" source="center"
                                    reference="centers" allowEmpty>
                        <SelectInput style = {styles.horizontalMargin} optionText="name"/>
                    </ReferenceInput>
                    <ConditionalInput style = {styles.horizontalMargin} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                        <TextInput style = {styles.horizontalMargin} source="student_code" label="Código" validate={required}/>
                        <TextInput style = {styles.horizontalMargin} source="student_name" label="Nombre" options={{fullWidth: true}} validate={required}/>
                    </ConditionalInput>
                    <ConditionalInput style = {styles.horizontal} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                    <SelectInput
                        source="coordinador"
                        label="coordinador que va a preconceptuar"
                        choices={[
                        { id: "", name: " " },
                        { id: "1", name: "Regencia de Farmacia" },
                        { id: "2", name: "Empresarial" },
                        { id: "3", name: "Artes" },
                        { id: "4", name: "Judicial" },
                        { id: "5", name: "AgroIndustrial" },
                        ]}
                    />
                        <TextInput style = {styles.horizontalMargin} source="start_period" label="Inicio" validate={required}/>
                        <TextInput style = {styles.horizontalMargin} source="level" label="Nivel" validate={required}/>
                        <TextInput style = {styles.horizontalMargin} source="gpa" label="Promedio acumulado"/>
                        <TextInput style = {styles.horizontalMargin} source="cc" label="Créditos cursados"/>
                        <TextInput style = {styles.horizontalMargin} source="ca" label="Créditos aprobados"/>
                        <TextInput style = {styles.horizontalMargin} source="email" label="Correo"/>
                        <TextInput style = {styles.horizontalMargin} source="cm" label="Créditos matriculados"/>
                        <DateTimeInput style = {styles.horizontalFecha} source="request_date" label="Fecha de solicitud" optionsTime={{format: 'ampm'}}/>
                        <ReferenceInput style = {styles.horizontalMargin} label="Condicionalidad" source="student_conditionality"
                                        reference="studentconditionalities" allowEmpty validate={required}>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <RichTextInput source="request" label="Solicitud" toolbar={[['bold']]}/>
                    </ConditionalInput>
                    <TinyMCEInput source="description" label="Descripción"/>
                    <FileInput
                        source="attachments"
                        label="Archivos de soporte"
                        accept={ACCEPTED_MIME_TYPES}
                        multiple>
                        <FileField source="url" title="filename"/>
                    </FileInput>
                </SimpleForm>
            </Create>
        );
    }
}

CreateIssue.propTypes = {
    selectedCouncil: PropTypes.string,
    userCouncils: PropTypes.array
};

const mapStateToProps = (state) => ({
    selectedCouncil: state.council.selected,
    userCouncils: state.council.list
});

export default connect(mapStateToProps)(CreateIssue)
