import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import shouldUpdate from 'recompose/shouldUpdate';
import {TableBody, TableRow, TableRowColumn} from 'material-ui/Table';
import DatagridCell from './DatagridCell';
import HtmlParser from 'react-html-parser';
import Highlighter from 'react-highlight-words';
import { findAll } from 'highlight-words-core';
import get from 'lodash/get';

import {ISSUE_TYPE_STUDENTS} from '../../../../constants';

const transform = (listFilter) => (node) => {
    if (node.type === 'tag' && (node.name === 'img' || node.name === 'table'))
        return null;

    if (node.type === 'text') {
        const chunks = findAll({
            caseSensitive: false,
            searchWords: [listFilter.postSearch],
            textToHighlight: node.data
        });

        let shouldShow = false;
        for (let i = 0; i < chunks.length; i++) {
            if (chunks[i].highlight) {
                shouldShow = true;
                break;
            }
        }

        if (!shouldShow) {
            return null;
        }

        return chunks
            .map((chunk, index) => {
                const { end, highlight, start } = chunk;
                const text = node.data.substr(start, end - start);
                if (highlight) {
                    return <mark key={index}>{text}</mark>;
                } else {
                    return <Fragment key={index}>{text.substr(0, text.length >= 20 ? 20 : text.length) + '...'}</Fragment>;
                }
            });
    }
};

const DatagridBody = ({
                          resource,
                          children,
                          ids,
                          isLoading,
                          data,
                          basePath,
                          styles,
                          rowStyle,
                          options,
                          rowOptions,
                          listFilter,
                          dispatch,
                          ...rest
                      }) => (
    <TableBody
        displayRowCheckbox={false}
        className="datagrid-body"
        {...rest}
        {...options}
    >
        {ids.map((id, rowIndex) => (
            <Fragment key={id + 'container'}>
                <TableRow
                    style={
                        {
                            ...rowStyle ? rowStyle(data[id], rowIndex) : styles.tr,
                            background:  data[id] && data[id].metadata && data[id].metadata.issues ? 'aliceblue' : 'white'
                        }
                    }
                    key={id}
                    selectable={false}
                    {...rowOptions}
                >
                    {React.Children.map(
                        children,
                        (field, index) =>
                            field ? (
                                <DatagridCell
                                    key={`${id}-${field.props.source || index}`}
                                    className={`column-${field.props.source}`}
                                    record={data[id]}
                                    defaultStyle={
                                        index === 0 ? (
                                            styles.cell['td:first-child']
                                        ) : (
                                            styles.cell.td
                                        )
                                    }
                                    {...{field, basePath, resource}}
                                />
                            ) : null
                    )}
                </TableRow>
                {
                    data[id] && data[id].metadata && data[id].metadata.issues && data[id].metadata.issues.map(issue =>
                        <TableRow
                            className="detail"
                            key={id + 'detail' + issue.id}
                            selectable={false}
                            {...rowOptions}
                        >
                            <TableRowColumn colSpan={7} style={{fontSize: '10px !important'}}>
                                <p>Activitidad: {issue.activity}</p>
                                <p>Tema: {<Highlighter searchWords={[listFilter.postSearch]} textToHighlight={issue.title}/>}</p>
                                <div style={{fontSize: '10px !important'}}>
                                    {issue.type === ISSUE_TYPE_STUDENTS && <p>Solicitud: {HtmlParser(issue.request, { transform: transform(listFilter)})}</p>}
                                    {issue.type === ISSUE_TYPE_STUDENTS && <p>Conceptos: {HtmlParser(issue.inline_feedback, { transform: transform(listFilter)})}</p>}
                                    {issue.type === ISSUE_TYPE_STUDENTS && <p>Comentarios: {HtmlParser(issue.inline_comment, {transform: transform(listFilter)})}</p>}
                                    {
                                        HtmlParser(issue.description, { transform: transform(listFilter)})
                                    }
                                </div>
                            </TableRowColumn>
                        </TableRow>
                    )
                }
            </Fragment>
        ))}
    </TableBody>
);

DatagridBody.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool,
    resource: PropTypes.string,
    data: PropTypes.object.isRequired,
    basePath: PropTypes.string,
    options: PropTypes.object,
    rowOptions: PropTypes.object,
    styles: PropTypes.object,
    rowStyle: PropTypes.func,
    listFilter: PropTypes.object
};

DatagridBody.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    connect((state) => ({listFilter: get(state, 'admin.resources.acts.list.params.filter')})),
    shouldUpdate(
        (props, nextProps) => nextProps.isLoading === false
    )
);

const PureDatagridBody = enhance(DatagridBody);

// trick material-ui Table into thinking this is one of the child type it supports
PureDatagridBody.muiName = 'TableBody';

export default PureDatagridBody;
