import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
//import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { fetchJson } from "../../providers/RestClient/fetch";
import { API_ENDPOINT } from "../../constants";
//import createReport from 'docx-templates';
// descarga un excel
//let xlsx = require("json-as-xlsx");

import ReactExport from 'react-data-export';
//import JSZipUtils from 'jszip-utils';
//import FileSaver from 'file-saver';
import moment from 'moment';
import localeEs from 'moment/locale/es';
//import { MEDIUM } from 'material-ui/utils/withWidth';
//import { emphasize } from 'material-ui/utils/colorManipulator';
import storage from "../../providers/RestClient/storage";
moment.updateLocale('es', localeEs);


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;




const styles = theme => ({
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
});

const ACTIVITY_STUDENTS = 'studiantes';
const PROP_CENTERS = 'centers';
const PROP_CONDITIONALITIES = 'studentConditionalities';




class ExportTableButton extends Component {
    state = {
        isExporting: false
    };
    componentWillUnmount() {
        this.setState({ isExporting: false });
    }
    constructor(props) {
        super(props)
        this.state = {
            datos: []
        }
        this.findOrEmpty = (what, id) => {
            return this.props[what].find(e => e.id === id) || {};
        };
    }

    createSpecAndExport(act){
        //pasa el id de los temas del acta
        const studentsActivity =
            act.activities.find(activity => activity.title.includes(ACTIVITY_STUDENTS));


        const actSpec = {
            act_number: act.number,
            act_date: act.ends_at ? moment(act.ends_at).format('DD [de] MMMM [del] YYYY') : '',
            secretary: 'algun nombre',
            starts_at: act.begins_at ? moment(act.begins_at).format('hh:mm A') : '',
            ends_at: act.ends_at ? moment(act.ends_at).format('hh:mm A') : '',
            place: act.place,

            students: studentsActivity.issues.map((issue, index) => ({
                ...issue,
                index,
                request: issue.request || '',
                inline_feedback: issue.inline_feedback || "<p>Sin concepto</p>",
                inline_comment: issue.inline_comment || "<p>Sin comentario</p>",
                inline_feedback_I: issue.inline_feedback_I || "<p> </p>",
                inline_comment_I: issue.inline_comment_I || "<p> </p>",
                center_and_program: `${this.findOrEmpty(PROP_CENTERS, issue.center).name} - ${issue.program}`,
                student_conditionality: `${this.findOrEmpty(PROP_CONDITIONALITIES, issue.student_conditionality).name}`,
                created_at: moment(issue.created_at).format('L'),
                request_date: moment(issue.request_date).format('DD [de] MMMM [del] YYYY'),
            }))
        };
        this.setState({ datos: actSpec });
        //console.log("hay " + this.state.datos.students.length+ "datos de estudiantes");
        //console.log(this.state.datos+" uno");
        // console.log(this.state.datos.students[1].inline_comment);
    }

    exportAsWord(){
        //this.setState({ isExporting: true });
        fetchJson(`${API_ENDPOINT}/acts/${this.props.actId}?includeIssues`)
            .then(response => {
                //let algo =response.data;
                console.log('exportAsWord',response);
                this.createSpecAndExport(response.json);

            })
            .catch(() => {
                // this.setState({ isExporting: false });
            });
        //Va a leer si ya tiene contenido datos

        if (this.state.datos.length != 0) {

            //Estilos de celda para el excel.
            //Estilo para el encabezado del acta
            const estiloE = { font: { name: "Humanst521 BT", sz: "10" }, alignment: { vertical: "center", horizontal: "bottom" } }
            //Encabezado de la tabla
            const estiloET = { font: { name: "Humanst521 BT", sz: "11", bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } } }
            //inicio iz
            const estiloETI = { font: { name: "Humanst521 BT", sz: "11", bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" } } }
            //inicio der
            const estiloETD = { font: { name: "Humanst521 BT", sz: "11", bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { top: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } } }
            //Datos de tabla
            const estiloT = { font: { name: "Humanst521 BT", sz: "11" }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } } }

            var data2;
            //si es un acta de programas o si es un acta de de insituto
            if (this.state.datos.students[0].council == '5ac2afaabe73a72aba3a7db0') {
                data2 = [

                    [
                        { value: " " },
                        { value: " " },
                        { value: " " },
                        { value: "Consejo de Programas", style: estiloE }

                    ],
                    [
                        { value: " " },
                        { value: " " },
                        { value: " " },
                        { value: "Casos de Estudiantes Acta N°" + this.state.datos.act_number, style: estiloE },
                    ],
                    [
                        { value: " " },
                        { value: " " },
                        { value: " " },
                        { value: this.state.datos.act_date, style: estiloE },
                    ],

                    [

                    ],

                    [
                        { value: " " },
                        { value: "No.", style: estiloET },
                        { value: "Nombres y Apellidos", style: estiloET },
                        { value: "Código", style: estiloET },
                        { value: "Programa", style: estiloET },
                        { value: "Inicio", style: estiloETI },
                        { value: " ", style: estiloETD },
                        { value: "Nivel", style: estiloET },
                        { value: "Prom.", style: estiloET },
                        { value: "CC", style: estiloET },
                        { value: "CA", style: estiloET },
                        { value: "Cond.", style: estiloET },
                        { value: "Fecha solicitud", style: estiloET },
                        { value: "Solicitud", style: estiloET },
                        { value: "Comentarios", style: estiloET },
                        { value: "Concepto Consejo de Programas", style: estiloET },
                        //{value: "Concepto Consejo de Instituto", style: {font: {sz: "10", bold: true}}},

                    ],
                ];
            }
            if (this.state.datos.students[0].council == '5c5b0ede1297f62607dae17b') {
                data2 = [

                    [
                        { value: " " },
                        { value: " " },
                        { value: " " },
                        { value: "Consejo de Instituto", style: estiloE }

                    ],
                    [
                        { value: " " },
                        { value: " " },
                        { value: " " },
                        { value: "Casos de Estudiantes Acta N°" + this.state.datos.act_number, style: estiloE },
                    ],
                    [
                        { value: " " },
                        { value: " " },
                        { value: " " },
                        { value: this.state.datos.act_date, style: estiloE },
                    ],

                    [

                    ],

                    [
                        { value: " " },
                        { value: "No.", style: estiloET },
                        { value: "Nombres y Apellidos", style: estiloET },
                        { value: "Código", style: estiloET },
                        { value: "Programa", style: estiloET },
                        { value: "Inicio", style: estiloETI },
                        { value: " ", style: estiloETD },
                        { value: "Nivel", style: estiloET },
                        { value: "Prom.", style: estiloET },
                        { value: "CC", style: estiloET },
                        { value: "CA", style: estiloET },
                        { value: "Cond.", style: estiloET },
                        { value: "Fecha solicitud", style: estiloET },
                        { value: "Solicitud", style: estiloET },
                        { value: "Comentarios", style: estiloET },
                        { value: "Concepto Consejo de Programas", style: estiloET },
                        { value: "Concepto Consejo de Instituto", style: estiloET },

                    ],
                ];
            }

            for (let index = 0; index < this.state.datos.students.length; index++) {
                //prueba


                //separar el periodo en el que comenzó por año y por semestre
                var periodoComienzo = this.state.datos.students[index].start_period.search('/') > 0
                ? this.state.datos.students[index].start_period.split("/")
                : this.state.datos.students[index].start_period.split("-");

                //quitar etiquetas html y la cadena de caracteres &nbsp;
                var solicitud = this.state.datos.students[index].request;
                solicitud = solicitud.replace(/<[^>]*>?/g, '');
                solicitud = solicitud.replace(/&nbsp;/g, ' ');


                var comentario = (this.state.datos.students[index].inline_comment);
                comentario = comentario.replace(/<[^>]*>?/g, '');
                comentario = comentario.replace(/&nbsp;/g, ' ');

                var concepto1 = (this.state.datos.students[index].inline_feedback);
                concepto1 = concepto1.replace(/<[^>]*>?/g, '');
                concepto1 = concepto1.replace(/&nbsp;/g, ' ');

                var comentarioI = this.state.datos.students[index].inline_comment_I;
                comentarioI = comentarioI.replace(/<[^>]*>?/g, '');
                comentarioI = comentarioI.replace(/&nbsp;/g, ' ');

                var conceptoI = this.state.datos.students[index].inline_feedback_I;
                conceptoI = conceptoI.replace(/<[^>]*>?/g, '');
                conceptoI = conceptoI.replace(/&nbsp;/g, ' ');

                var data3 = [

                    [

                        { value: " " },
                        { value: index + 1 ?? '', style: estiloT },
                        { value: this.state.datos.students[index].student_name ?? '', style: estiloT },
                        { value: this.state.datos.students[index].student_code ?? '', style: estiloT },
                        { value: this.state.datos.students[index].center_and_program ?? '', style: estiloT },
                        { value: periodoComienzo[0] ?? '', style: estiloT },
                        { value: periodoComienzo[1] ?? '', style: estiloT },
                        { value: this.state.datos.students[index].level ?? '', style: estiloT },
                        { value: this.state.datos.students[index].gpa ?? '', style: estiloT },
                        { value: this.state.datos.students[index].cc ?? '', style: estiloT },
                        { value: this.state.datos.students[index].ca ?? '', style: estiloT },
                        { value: this.state.datos.students[index].student_conditionality ?? '', style: estiloT },
                        { value: this.state.datos.students[index].request_date ?? '', style: estiloT },
                        { value: solicitud ?? '', style: estiloT },
                        { value: comentario ?? '', style: estiloT },
                        { value: concepto1 ?? '', style: estiloT },
                        storage.load('concejo') == 'Consejo de Instituto' ?
                            ({ value: conceptoI ?? '', style: estiloT }) : null,

                    ],
                ];
                data2.push.apply(data2, data3)
            }
        }
        else {
            data2 = []
        }
        var multiDataSet = [
            {
                columns: [// se asigna el ancho de las columnas.
                    { title: " ", width: { wch: 6.5 } },
                    { title: " ", width: { wch: 6.5 } },
                    { title: " ", width: { wch: 21.5 } },
                    { title: " ", width: { wch: 8.8 } },
                    { title: " ", width: { wch: 17.5 } },
                    { title: " ", width: { wch: 8.3 } },
                    { title: " ", width: { wch: 2.54 } },
                    { title: " ", width: { wch: 6.2 } },
                    { title: " ", width: { wch: 7.9 } },
                    { title: " ", width: { wch: 6.2 } },
                    { title: " ", width: { wch: 7.6 } },
                    { title: " ", width: { wch: 16 } },
                    { title: " ", width: { wch: 14.4 } },
                    { title: " ", width: { wch: 39.7 } },
                    { title: " ", width: { wch: 34.5 } },
                    { title: " ", width: { wch: 34.5 } },
                    { title: " ", width: { wch: 35.3 } },
                    { title: " ", width: { wpx: 1 } },
                ],
                data: data2,//se asigna la variable data2 a data ya que data es necesaria y data2 traeria la estructura del datasheet
            }
        ];
        return multiDataSet


    }


    render() {
        const { disabled, classes } = this.props;
        const { isExporting } = this.state;
        return (
            <div>
                <ExcelFile filename={"Acta " + this.state.datos.act_number + " - Casos de estudiantes "} element={<Button color="inherit"
                    disabled={disabled || isExporting}>
                    <InsertDriveFile className={classNames(classes.leftIcon, classes.iconSmall)} />Exportar</Button>}>
                    <ExcelSheet dataSet={this.exportAsWord()} name="Casos Estudiantes" />
                </ExcelFile>
            </div>
        );
    }
}

ExportTableButton.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    actId: PropTypes.string,
    studentIssueTypes: PropTypes.array,
    studentConditionalities: PropTypes.array,
    centers: PropTypes.array,
    beforeExport: PropTypes.func,
};

ExportTableButton.defaultProps = {
    disabled: false
};

export default withStyles(styles)(ExportTableButton);