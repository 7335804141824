import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import KeyboardArrowLeft from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import CircularProgress from 'material-ui/CircularProgress';

export class PrevButton extends Component {
    handleClick = e => {
        if (this.props.saving) {
            // prevent double submission
            e.preventDefault();
        } else {
            // always submit form explicitly regardless of button type
            const { handleSubmitWithRedirect, redirect } = this.props;
            if (e) {
                e.preventDefault();
            }
            handleSubmitWithRedirect(redirect)();
        }
    };

    render() {
        const {
            saving,
            label = 'Anterior',
            raised = true,
            submitOnEnter,
            redirect,
        } = this.props;
        const type = submitOnEnter ? 'submit' : 'button';
        const ButtonComponent = raised ? RaisedButton : FlatButton;
        return (
            <ButtonComponent
                type={type}
                label={label}
                icon={
                    saving && saving.redirect === redirect ? (
                        <CircularProgress size={25} thickness={2} />
                    ) : (
                        <KeyboardArrowLeft />
                    )
                }
                onClick={this.handleClick}
                secondary={!saving}
                style={{
                    margin: '10px 24px',
                    position: 'relative',
                }}
            />
        );
    }
}

PrevButton.propTypes = {
    label: PropTypes.string,
    raised: PropTypes.bool,
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    submitOnEnter: PropTypes.bool,
    handleSubmitWithRedirect: PropTypes.func,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PrevButton.defaultProps = {
    submitOnEnter: false,
    handleSubmitWithRedirect: () => () => {},
};

const mapStateToProps = state => ({
    saving: state.admin.saving,
});

export default connect(mapStateToProps)(PrevButton);
