import create from './create';
import edit from './edit';
import list from './list';

import Description from 'material-ui/svg-icons/action/description';

const resource = 'miembrosConcejo';

export default {
    key: resource,
    name: resource,
    options: {
        label: 'Miembros del Concejo'
    },
    icon: Description,
    create,
    edit,
    list,
};
