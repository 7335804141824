import React from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, EditButton } from '../../mui/admin-on-rest';
import ColorField from '../../mui/ColorField';

export default (props) => (
    <List title="Estados de temas" {...props}>
        <Datagrid>
            <ReferenceField label="Nombre" source="id" reference="issuestates" linkType={false}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="created_at" label="Creado en" showTime/>
            <DateField source="updated_at" label="Editado en" showTime/>
            <ColorField source="color"/>
            <EditButton />
        </Datagrid>
    </List>
);
