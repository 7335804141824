import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import Chip from 'material-ui/Chip';
import { blue300 } from 'material-ui/styles/colors';
import {connect} from 'react-redux';

class OptionTextChipField extends Component {
    static propTypes = {
        addLabel: PropTypes.bool,
        elStyle: PropTypes.object,
        label: PropTypes.string,
        record: PropTypes.object,
        optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    };

    static defaultProps = {
        addLabel: true
    };

    state = {
        focused: false
    };

    onBlur = () => {
        this.setState({focused: false});
    };

    onFocus = () => {
        this.setState({focused: true});
    };

    render() {
        const {
            record,
            studentIssueTypes,
            programs,
            centers,
            optionText
        } = this.props;

        const choiceText =
            typeof optionText === 'function'
                ? optionText(record, {studentIssueTypes, programs, centers})
                : get(record, optionText);

        return (
            <Chip
                style={this.props.style}
                labelStyle={this.props.labelStyle}
                backgroundColor={this.state.focused ? blue300 : null}
                onMouseEnter={this.onFocus}
                onMouseLeave={this.onBlur}
                onClick={
                    () => {this.props.onClick && this.props.onClick(get(this.props.record, 'id'))}
                }
            >
                { choiceText }
            </Chip>);
    }

}

const mapStateToProps = (state) => ({
    centers: state.admin.resources.centers.data || {},
    programs: state.admin.resources.programs.data || {},
    studentIssueTypes: state.admin.resources.studentissuetypes.data || {},
});

export default connect(mapStateToProps)(OptionTextChipField);
