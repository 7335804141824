import React from 'react';
import { SimpleForm, TextInput, DateField, DisabledInput, required } from '../../mui/admin-on-rest';
import Edit from '../../mui/Edit';

const EditTitle = ({ singular, record }) => {
    return <span>Editar {singular}{record ? `: "${record.name}"` : ''}</span>;
};

export default (internalProps) => (props) => (
    <Edit title={<EditTitle singular={internalProps.singular}/>} {...props}>
        <SimpleForm>
            <DisabledInput source="id" label="Id"/>
            <DateField source="created_at" label="Creado en" showTime/>
            <TextInput source="name" label="Nombre" validate={required}/>
        </SimpleForm>
    </Edit>
);
