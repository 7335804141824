import React from 'react';
import { Create, SimpleForm, TextInput, required } from '../../mui/admin-on-rest';

export default (internalProps) => (props) => (
    <Create title={'Nuevo ' + internalProps.singular + ':'} {...props}>
        <SimpleForm>
            <TextInput source="name" label="Nombre" validate={required}/>
        </SimpleForm>
    </Create>
);
