import React from 'react';
import Admin from './mui/Admin';
import { Resource, Delete } from './mui/admin-on-rest';
import messages from './providers/Messages';
import restClient, { authClient } from './providers/RestClient';
import { API_ENDPOINT } from "./constants";

import Layout from './mui/Layout';

import Acts from './resources/Acts';
import Issues from './resources/Issues';
import Users from './resources/Users';
import JustName from './resources/JustName';
import Login from './mui/Login';
import IssueStates from './resources/IssueStates';
import MiembrosConcejos from './resources/MiembrosConcejos';

import Description from 'material-ui/svg-icons/action/description';
import ImportContacts from 'material-ui/svg-icons/communication/import-contacts';
import SupervisorAccount from 'material-ui/svg-icons/action/supervisor-account';
import HdrStrong from 'material-ui/svg-icons/image/hdr-strong';
import Apps from 'material-ui/svg-icons/navigation/apps';
import Layers from 'material-ui/svg-icons/maps/layers';
import AccountBalance from 'material-ui/svg-icons/action/account-balance';
import Business from 'material-ui/svg-icons/communication/business';

import councilReducer from './reducers/council';
import promptReducer from './reducers/prompt';

import tinymce from 'tinymce/tinymce';
// react-tinymce use global ref
window.tinymce = tinymce;

import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

import './App.css';

const IssueTypes = JustName({ plural: 'Tipos de temas', singular: 'tipo de tema', reference: 'issuetypes' });
const StudentIssueTypes = JustName({ plural: 'Tipos de temas de estudiantes', singular: 'tipo de tema de estudiante', reference: 'studentissuetypes' });
const StudentConditionalities = JustName({ plural: 'Tipos de condicionalidad', singular: 'tipo de condicionalidad', reference: 'studentconditionalities' });
const CommentTypes = JustName({ plural: 'Tipos de comentario', singular: 'tipo de comentario', reference: 'commenttypes' });
const Programs = JustName({ plural: 'Programas', singular: 'programa', reference: 'programs' });
const Councils = JustName({ plural: 'Consejos', singular: 'consejo', reference: 'councils' });
const Centers = JustName({ plural: 'Sedes', singular: 'sede', reference: 'centers' });

const consolidate = role => {
    switch (role) {
        case 'commentator':
            //Este sería la vista de los coordinadores que deberían preconceptuar un tema
            return [
                <Resource icon={Description} name="acts" options={{ label: 'Actas' }} list={Acts.list} show={Acts.show} />,
                <Resource icon={ImportContacts} name="issues" options={{ label: 'Temas' }} list={Issues.list} create={Issues.create}
                    edit={Issues.edit} show={Issues.show} filters={Issues.filter} remove={Delete} />,
                <Resource name="councils" />,
                <Resource name="issuestates" />,
                <Resource name="issuetypes" />,
                <Resource name="programs" />,
                <Resource name="_acts" />,
                <Resource name="_issues" />,
                <Resource name="clients" edit={Users.edit} />,
                <Resource name="commenttypes" />,
                <Resource name="centers" />,
                <Resource icon={Apps} name="studentissuetypes" />,
                <Resource icon={Apps} name="studentconditionalities" />
            ];
        case 'readOnly':
            //será los miembros que no deben preconceptuar un tema
            return [

                <Resource icon={Description} name="acts" options={{ label: 'Actas' }} />,
                <Resource icon={ImportContacts} name="issues" options={{ label: 'Temas' }} list={Issues.list}
                    show={Issues.show} />,
                <Resource name="councils" />,
                <Resource name="issuestates" />,
                <Resource name="issuetypes" />,
                <Resource name="programs" />,
                <Resource name="_acts" />,
                <Resource name="_issues" />,
                <Resource name="clients" />,
                <Resource name="commenttypes" />,
                <Resource name="centers" />,
                <Resource name="roles" />,
                <Resource icon={Apps} name="studentissuetypes" />,
                <Resource icon={Apps} name="studentconditionalities" />
            ];
        case 'coordinator':
            //será el miembro presidente del concejo
            return [
                <Resource icon={Description} name="acts" options={{ label: 'Actas' }} list={Acts.list} create={Acts.create} edit={Acts.edit}
                    show={Acts.show}
                    remove={Delete} />,
                <Resource icon={ImportContacts} name="issues" options={{ label: 'Temas' }} list={Issues.list} create={Issues.create}
                    edit={Issues.edit} show={Issues.show} filters={Issues.filter} remove={Delete} />,
                <Resource name="councils" />,
                <Resource name="issuestates" />,
                <Resource name="issuetypes" />,
                <Resource name="programs" />,
                <Resource name="_acts" />,
                <Resource name="_issues" />,
                <Resource name="clients" edit={Users.edit} />,
                <Resource name="commenttypes" />,
                <Resource name="centers" />,
                <Resource icon={Apps} name="studentissuetypes" />,
                <Resource icon={Apps} name="studentconditionalities" />
            ];
        case 'secretary':
            //La secretaria del concejo
            return [
                <Resource icon={Description} name="acts" options={{ label: 'Actas' }} list={Acts.list} create={Acts.create} edit={Acts.edit}
                    remove={Delete} />,
                <Resource icon={ImportContacts} name="issues" options={{ label: 'Temas' }} list={Issues.list} create={Issues.create}
                    edit={Issues.edit} filters={Issues.filter} remove={Delete} />,
                <Resource name="councils" />,
                <Resource name="issuestates" />,
                <Resource name="issuetypes" />,
                <Resource name="programs" />,
                <Resource name="_acts" />,
                <Resource name="_issues" />,
                <Resource name="clients" edit={Users.edit} />,
                <Resource name="commenttypes" />,
                <Resource name="centers" />,
                <Resource icon={Apps} name="studentissuetypes" />,
                <Resource icon={Apps} name="studentconditionalities" />,
                <Resource name={MiembrosConcejos.name} icon={MiembrosConcejos.icon}  options={MiembrosConcejos.options} list={MiembrosConcejos.list} create={MiembrosConcejos.create} 
                 edit={MiembrosConcejos.edit} remove={Delete} />,
            ];
        default:
            //Será el administrador
            return [
                <Resource icon={Description} name="acts" options={{ label: 'Actas' }} list={Acts.list} create={Acts.create} edit={Acts.edit}
                    remove={Delete} />,
                <Resource icon={ImportContacts} name="issues" options={{ label: 'Temas' }} list={Issues.list} create={Issues.create}
                    edit={Issues.edit} filters={Issues.filter} remove={Delete} />,
                <Resource icon={HdrStrong} name="issuestates" options={{ label: 'Estados de temas' }} list={IssueStates.list}
                    create={IssueStates.create} edit={IssueStates.edit} remove={Delete} />,
                <Resource icon={Apps} name="issuetypes" options={{ label: 'Tipos de temas' }} list={IssueTypes.list}
                    create={IssueTypes.create} edit={IssueTypes.edit} remove={Delete} />,
                <Resource icon={Apps} name="studentissuetypes" options={{ label: 'Tipos de tema de estudiante' }} create={StudentIssueTypes.create} edit={StudentIssueTypes.edit} list={StudentIssueTypes.list} remove={Delete} />,
                <Resource icon={Apps} name="studentconditionalities" options={{ label: 'Tipos de condicionalidad' }} create={StudentConditionalities.create} edit={StudentConditionalities.edit} list={StudentConditionalities.list} remove={Delete} />,
                <Resource icon={Layers} name="programs" options={{ label: 'Programas' }} list={Programs.list} create={Programs.create}
                    edit={Programs.edit} remove={Delete} />,
                <Resource icon={AccountBalance} name="councils" options={{ label: 'Consejos' }} list={Councils.list} create={Councils.create}
                    edit={Councils.edit} remove={Delete} />,
                <Resource icon={Business} name="centers" options={{ label: 'Sedes' }} list={Centers.list} create={Centers.create} edit={Centers.edit} remove={Delete} />,
                <Resource name="commenttypes" options={{ label: 'Tipos de comentarios' }} list={CommentTypes.list} create={CommentTypes.create}
                    edit={CommentTypes.edit} remove={Delete} />,
                <Resource name="_acts" />,
                <Resource name="_issues" />,
                <Resource name={MiembrosConcejos.name} icon={MiembrosConcejos.icon}  options={MiembrosConcejos.options} list={MiembrosConcejos.list} create={MiembrosConcejos.create} 
                 edit={MiembrosConcejos.edit} remove={Delete} />,
                <Resource name="clients" icon={SupervisorAccount} options={{ label: 'Usuarios' }} list={Users.list} edit={Users.edit} create={Users.create} remove={Delete} />,
                <Resource name="roles" />
            ]
    }
};

export default () => (
    <Admin title="SIGER"
        locale="es"

        messages={messages}
        loginPage={Login}
        appLayout={Layout}
        customReducers={{ council: councilReducer, prompt: promptReducer }}
        authClient={authClient(API_ENDPOINT, '/clients/login?include=user')}
        restClient={restClient(API_ENDPOINT)}>
        {
            permissions => consolidate(permissions)
        }
    </Admin>
);
