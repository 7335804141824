import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Prompt} from 'react-router-dom';
import {Card, CardText} from 'material-ui/Card';
import Dialog from 'material-ui/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import inflection from 'inflection';
import {reset, isDirty} from 'redux-form';
import ViewTitle from '../admin-on-rest/mui/layout/ViewTitle';
import Title from '../admin-on-rest/mui/layout/Title';
import {
    crudGetOne as crudGetOneAction,
    crudUpdate as crudUpdateAction
} from '../admin-on-rest/actions/dataActions';
import {togglePrompt as togglePromptAction} from "../../actions";
import DefaultActions from '../admin-on-rest/mui/detail/EditActions';
import translate from '../admin-on-rest/i18n/translate';
import withPermissionsFilteredChildren from '../admin-on-rest/auth/withPermissionsFilteredChildren';

export class Edit extends Component {
    componentDidMount() {
        this.updateData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            this.props.id !== nextProps.id ||
            nextProps.version !== this.props.version
        ) {
            this.props.resetForm('record-form');
            this.updateData(nextProps.resource, nextProps.id);
        }
    }

    getBasePath() {
        const {location} = this.props;
        return location.pathname
            .split('/')
            .slice(0, -1)
            .join('/');
    }

    static defaultRedirectRoute() {
        return 'list';
    }

    updateData(resource = this.props.resource, id = this.props.id) {
        this.props.crudGetOne(resource, id, this.getBasePath());
    }

    save = (record, redirect) => {
        if (this.props.onSave) {
            this.props.onSave(this.props.id, this.getBasePath(), record, redirect);
        }

        if (redirect === 'prev' || redirect === 'next') {
            redirect = ''
        }

        this.props.crudUpdate(
            this.props.resource,
            this.props.id,
            record,
            this.props.data,
            this.getBasePath(),
            redirect
        );
    };

    render() {
        const {
            actions = <DefaultActions/>,
            children,
            data,
            hasDelete,
            hasShow,
            hasList,
            id,
            isLoading,
            resource,
            title,
            translate,
            version,
            hideContent = false,
        } = this.props;

        if (!children) return null;

        const basePath = this.getBasePath();

        const resourceName = translate(`resources.${resource}.name`, {
            smart_count: 1,
            _: inflection.humanize(inflection.singularize(resource)),
        });
        const defaultTitle = translate('aor.page.edit', {
            name: `${resourceName}`,
            id,
            data,
        });
        const titleElement = data ? (
            <Title title={title} record={data} defaultTitle={defaultTitle}/>
        ) : (
            ''
        );
        const dialogActions = [
            <FlatButton
                label="No"
                primary={true}
                onClick={() => {
                    if (this.props.prompt.callback) {
                        this.props.prompt.callback(false);
                    }
                    this.props.togglePrompt();
                }}
            />,
            <FlatButton
                label="Sí"
                primary={true}
                onClick={() => {
                    if (this.props.prompt.callback) {
                        this.props.prompt.callback(true);
                    }
                    this.props.togglePrompt();
                }}
            />,
        ];

        return (
            <div className="edit-page">
                <Prompt
                    when={this.props.dirty}
                    message="¿Deseas continuar sin guardar?"
                />
                <Card style={{opacity: isLoading ? 0.8 : 1}}>
                    {!hideContent && actions &&
                    React.cloneElement(actions, {
                        basePath,
                        data,
                        hasDelete,
                        hasShow,
                        hasList,
                        resource,
                    })}
                    {!hideContent && <ViewTitle title={titleElement}/>}
                    {data ? (
                        React.cloneElement(children, {
                            save: this.save,
                            resource,
                            basePath,
                            record: data,
                            translate,
                            version,
                            redirect:
                                typeof children.props.redirect === 'undefined'
                                    ? Edit.defaultRedirectRoute()
                                    : children.props.redirect,
                        })
                    ) : (
                        !hideContent && <CardText>&nbsp;</CardText>
                    )}
                    {
                        !data && hideContent &&
                        <div style={{padding: 20, textAlign: 'center'}}>
                            <CircularProgress color="primary" disableShrink={true} style={{color: "#871477"}}/>
                            <br/>
                            Cargando
                        </div>
                    }
                </Card>
                <Dialog
                    modal={false}
                    open={this.props.prompt.show}
                    actions={dialogActions}
                    onRequestClose={() => {
                        if (this.props.prompt.callback) {
                            this.props.prompt.callback(false);
                        }
                        this.props.togglePrompt();
                    }}
                >
                    {this.props.prompt.message}
                </Dialog>
            </div>
        );
    }
}

Edit.propTypes = {
    actions: PropTypes.element,
    children: PropTypes.node,
    crudGetOne: PropTypes.func.isRequired,
    crudUpdate: PropTypes.func.isRequired,
    data: PropTypes.object,
    hasDelete: PropTypes.bool,
    hasShow: PropTypes.bool,
    hasList: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    title: PropTypes.any,
    translate: PropTypes.func,
    version: PropTypes.number.isRequired,
    onSave: PropTypes.func
};

function mapStateToProps(state, props) {
    return {
        id: decodeURIComponent(props.match.params.id),
        data: state.admin.resources[props.resource]
            ? state.admin.resources[props.resource].data[
                decodeURIComponent(props.match.params.id)
            ]
            : null,
        isLoading: state.admin.loading > 0,
        version: state.admin.ui.viewVersion,
        prompt: state.prompt,
        dirty: isDirty('record-form')(state)
    };
}

const enhance = compose(
    connect(mapStateToProps, {
        crudGetOne: crudGetOneAction,
        crudUpdate: crudUpdateAction,
        resetForm: reset,
        togglePrompt: togglePromptAction
    }),
    translate,
    withPermissionsFilteredChildren
);

export default enhance(Edit);
