import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TableChart from '@material-ui/icons/TableChart';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getFormValues, submit } from 'redux-form';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { API_ENDPOINT, ISSUE_TYPE_STUDENTS } from '../../constants'; // para que consulte el tipo de estudiante nuevo
import { fetchJson } from "../../providers/RestClient/fetch";
import StudentsTable from "../StudentsTable";
import ExportTableButton from "../ExportTableButton";
import objectDiff from 'object-diff';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    center: {
        margin: 'auto',
    },
    centerText: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    paper: {
        width: '100%',
        margin: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    root: {
        width: '100%',
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
    },
    container: {
        padding: 16
    },
    tableTitle: {
        ...theme.typography.button,
        padding: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 2
    },
    noData: {
        ...theme.typography.subtitle2,
        paddingLeft: theme.spacing.unit * 2,
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const ACTIVITY_STUDENTS = 'studiantes';

class ShowTableButton extends Component {
    state = {
        open: false,
        isSaving: 0,
        errorFetching: false,
        errorSaving: false,
        issues: { fetching: false, data: [] },
        issuestates: { fetching: false, data: [] },
        studentissuetypes: { fetching: false, data: [] },
        studentconditionalities: { fetching: false, data: [] },
        centers: { fetching: false, data: [] },
        programs: { fetching: false, data: [] },
        isLoading: 0,
        afterSave: undefined
    };

    handleClickOpen = () => {
        this.setState({ open: true });
        this.fetchAll();
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        const currentActivity = get(this.props.formData, this.props.source);
        if (currentActivity.title.includes(ACTIVITY_STUDENTS)) {
            this.fetchData('issues', { "where": { "id": { "inq": currentActivity.issues } } });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevActivity = get(prevProps.formData, prevProps.source);
        const currentActivity = get(this.props.formData, this.props.source);

        if (!prevActivity || !prevActivity.title || !prevActivity.title.includes(ACTIVITY_STUDENTS) ||
            !currentActivity || !currentActivity.title || !currentActivity.title.includes(ACTIVITY_STUDENTS))
            return;

        if (!isEqual(prevActivity.issues, currentActivity.issues) && currentActivity.issues.length > 0) {
            this.fetchData('issues', { "where": { "id": { "inq": currentActivity.issues } } });
        }
    }

    componentWillUnmount(){}

    //organiza el array de estudiantes bajo el criterio de IssueType
    sendStudentOrderByTypeIssues = () => {
        let ordenar = this.state.issues.data;

        function sortresult(prop) {
            ordenar = ordenar.sort(function (a, b) {
                return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
            });
        }
        sortresult('student_issue_type');
        return ordenar;
    }

    issuesValid = () => {
        //dice por medio de un booleano si hay temas añadidas para tratar en el acta
        if (this.state.issues.data.length === 0)
            return true;

        const values = !this.state.issues.data.reduce((acc, issue) => acc && (issue.type === ISSUE_TYPE_STUDENTS));

        return values;
    };

    fetchAll() {
        const currentActivity = get(this.props.formData, this.props.source);

        if (currentActivity && currentActivity.issues.length > 0)
            this.fetchData('issues', { "where": { "id": { "inq": currentActivity.issues } } });

        this.fetchData('issuestates');
        this.fetchData('studentissuetypes');
        this.fetchData('studentconditionalities');
        this.fetchData('centers');
        this.fetchData('programs');
    }

    fetchData(what, filter) {
        if (this.state[what].fetching)
            return;

        this.setState(state => ({
            isLoading: state.isLoading + 1,
            errorFetching: false,
            [what]: { ...state[what], fetching: true }
        }));

        fetchJson(`${API_ENDPOINT}/${what}${filter ? `?filter=${encodeURIComponent(JSON.stringify(filter))}` : ''}`)
            .then(response => {
                if (this.state.errorFetching) {
                    return;
                }

                this.setState(state => ({
                    isLoading: state.isLoading - 1,
                    [what]: { data: response.json, fetching: false }
                }));
            })
            .catch(() => {
                this.setState(() => ({
                    isLoading: 0,
                    errorFetching: true,
                    [what]: { data: [], fetching: false }
                }));
            });
    }

    saveIssue = (issue) => {
        this.setState(state => ({
            isSaving: state.isSaving + 1,
            errorSaving: false
        }));

        fetchJson(`${API_ENDPOINT}/issues/${issue.id}`, { method: 'PATCH', body: JSON.stringify(issue) })
            .then(() => {
                if (this.state.errorSaving) {
                    return;
                }

                if (this.state.isSaving === 1 && this.state.afterSave) {
                    this.state.afterSave();
                    this.setState(state => ({
                        afterSave: undefined,
                        isSaving: state.isSaving - 1
                    }));
                } else {
                    this.setState(state => ({
                        //se inhabilita este open por motivos de que queremos que la ventana
                        //quede abierta despues de guardar
                        // open: state.isSaving !== 1,
                        isSaving: state.isSaving - 1,
                    }));
                }
            })
            .catch(() => {
                this.setState(() => ({
                    isSaving: 0,
                    errorSaving: true
                }));
            });
    };

    getInitialValue = (issueId) => this.state.issues.data.find(issue => issue.id === issueId);

    save = (values) => {
        let unsaved = 0;
        values.students.map(student => {
            const valueToSave = objectDiff(this.getInitialValue(student.id), student);
            if (Object.keys(valueToSave).length > 0) {
                this.saveIssue({ id: student.id, ...valueToSave });
            } else {
                unsaved++;
            }
        });

        if (unsaved === values.students.length) {
            if (this.state.afterSave) {
                this.state.afterSave();
            }

            this.setState({
                open: false,
                isSaving: 0,
                afterSave: undefined
            });
        }
    };

    render() {
        const { classes, formData, source } = this.props;
        const currentActivity = get(formData, source);

        return currentActivity.title && currentActivity.title.includes(ACTIVITY_STUDENTS) &&
            <div>
                <Button
                    variant="outlined"
                    className={classes.button}
                    disabled={this.issuesValid() || this.state.isLoading > 0}
                    onClick={this.handleClickOpen}>
                    <TableChart className={classNames(classes.leftIcon, classes.iconSmall)} />
                    Mostrar tabla
                </Button>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    //se inhabilita por desuso
                    // onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Temas de estudiantes
                            </Typography>
                            <ExportTableButton
                                beforeExport={(doExport) => {
                                    this.setState({ afterSave: doExport });
                                    this.props.submit('students-form');
                                }}
                                actId={this.props.actId}
                                studentIssueTypes={this.state.studentissuetypes.data}
                                studentConditionalities={this.state.studentconditionalities.data}
                                centers={this.state.centers.data}
                                disabled={this.state.isLoading > 0 || this.state.isSaving > 0}
                            />

                            <div className={classes.wrapper}>
                                <Button color="inherit"
                                    onClick={() => this.props.submit('students-form')}

                                    disabled={this.state.isLoading > 0 || this.state.isSaving > 0}>
                                    <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Guardar

                                </Button>

                                {

                                    this.state.isSaving > 0 &&
                                    <CircularProgress size={24} className={classes.buttonProgress}
                                        disableShrink={true} /> && alert("Guardado")

                                }
                            </div>

                        </Toolbar>
                    </AppBar>

                    {

                        this.state.isLoading > 0 ?
                            <CircularProgress className={classes.center} disableShrink={true} /> :
                            !this.state.errorFetching &&
                                this.state.issues.data &&
                                this.state.issues.data.length > 0 ?

                                <StudentsTable
                                    form="students-form"
                                    //initialValues le pasa el orden de los estudiantes 
                                    initialValues={{ students: this.sendStudentOrderByTypeIssues() }}
                                    students = {this.sendStudentOrderByTypeIssues()}
                                    studentIssueTypes={this.state.studentissuetypes}
                                    centers={this.state.centers}
                                    programs={this.state.programs}
                                    studentConditionalities={this.state.studentconditionalities}
                                    openDetail={(key) => {
                                        this.setState({
                                            afterSave: () => {
                                                this.setState({ open: false });
                                                this.props.openDetail(key, this.state.issues.data[key].id, this.state.issues.data.map(issue => issue.id));
                                            }
                                        });
                                        this.props.submit('students-form');
                                    }}
                                    onSubmit={this.save} /> :
                                <p className={classes.noData}>No hay estudiantes</p>
                    }
                    {
                        this.state.errorFetching &&
                        <div className={classes.center}>
                            <p className={classes.centerText}>Oops... algo ocurrío</p>
                            <Button
                                variant="outlined"
                                className={classes.button}
                                onClick={this.fetchAll.bind(this)}>
                                <CachedIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                Intentar nuevamente
                            </Button>
                        </div>
                    }
                </Dialog>
            </div>;
    }
}

ShowTableButton.propTypes = {
    classes: PropTypes.object.isRequired,
    formData: PropTypes.object,
    record: PropTypes.object,
    source: PropTypes.string,
    openDetail: PropTypes.func,
    dispatch: PropTypes.func,
    actId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    formData: getFormValues('record-form')(state) || {}
});

export default connect(mapStateToProps, { submit })(withStyles(styles)(ShowTableButton));
