export const ACCEPTED_MIME_TYPES =
    "application/pdf,image/jpeg,image/png,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword";

export const CREATE_COUNCIL_FILTER = (councils = []) => {
    if (councils.length) {
        return {where: {or: councils.map(council => ({id: council.id}))}}
    }

    return {};
};
