import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';

import './ColorInput.css';

const ColorFieldComponent = ({ source, record = {}, className }) =>
    (
        <div style={{ display: 'flex' }}>
            <div style={{
                width: '20px',
                height: '20px',
                background: get(record, source),
                marginRight: '5px',
            }}
            />
            <span className={className}>{get(record, source)}</span>
        </div>
    );

ColorFieldComponent.propTypes = {
    addLabel: PropTypes.bool,
    className: PropTypes.string,
    elStyle: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const PureTextField = pure(ColorFieldComponent);

PureTextField.defaultProps = {
    addLabel: true,
};

export default PureTextField;