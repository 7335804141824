import React from "react";
import {List, Datagrid, ReferenceField, TextField, BooleanField, ShowButton, FunctionField, EditButton} from "../../mui/admin-on-rest"
import storage from "../../providers/RestClient/storage";

const getFilter = () => {
    const fields = {
        id: true,
        nombre: true,
        cargo: true,
        posicion: true,
        concejo: true,
        activo: true,
    };

    let concejo = storage.load('COUNCIL')

    if (concejo) {
        return {
            where: {
                concejo
            },
            fields
        };
    } else {
        return {
            fields
        }
    }
};

const showButton = (permissions) => { //Se refiere al color de todos los botones
    if (permissions === 'readOnly' || permissions === 'commentator') {
        return <ShowButton />;
    } else if (permissions === 'coordinator') {
        return <FunctionField render={record => record.editable ?
            //Cambiar color de todos los botones
            <EditButton  basePath={'/acts'} record={record}/> : <ShowButton basePath={'/acts'} record={record}/>}/>
    } else {
        return <EditButton />
    }
};

export default (props) => (
    <List title="Miembros Concejo" {...props} filter={getFilter()}>
        {
            permissions => 
            <Datagrid>
                <ReferenceField label="Nombre" source="id" reference="miembrosConcejo" linkType={false}>
                    <TextField source="nombre"/>
                </ReferenceField>
                <ReferenceField label="Cargo" source="id" reference="miembrosConcejo" linkType={false}>
                    <TextField source="cargo"/>
                </ReferenceField>
                <ReferenceField label="Posición" source="id" reference="miembrosConcejo" linkType={false}>
                    <TextField source="posicion"/>
                </ReferenceField>
                {
                    showButton(permissions)
                }
            </Datagrid>
        }
    </List>
);
