import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Card, CardActions } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import CircularProgress from 'material-ui/CircularProgress';
import LockIcon from 'material-ui/svg-icons/action/lock-outline';
import { cyan500, pinkA200 } from 'material-ui/styles/colors';

import defaultTheme from '../admin-on-rest/mui/defaultTheme';
import { userLogin as userLoginAction } from '../admin-on-rest/actions/authActions';
import translate from '../admin-on-rest/i18n/translate';
import Notification from '../admin-on-rest/mui/layout/Notification';
import { ImageField } from 'admin-on-rest';
import storage from '../../providers/RestClient/storage';

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        minWidth: 300,
    },
    avatar: {
        margin: '1em',
        textAlign: 'center ',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        display: 'flex',
    },

    colorletra: { color: "#871477" }
};

function getColorsFromTheme(theme) {
    if (!theme) return { primary1Color: "#871477", accent1Color: "#871477" };
    const { palette: { primary1Color, accent1Color } } = theme;
    return { primary1Color, accent1Color };
}

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        errorText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
        underlineFocusStyle={{ 'borderBottom': '2px solid #871477' }}
        underlineDisabledStyle={{ 'borderBottom': '2px solid #871477' }}
        floatingLabelFocusStyle={{ color: '#871477' }}
    />
);

class Login extends Component {

    componentWillMount() {
        if (storage.load('role') === 'readOnly') {
            return
        }
        /*
        if (!storage.load('requestedLogin')) {
            this.login({username: 'consulta@siger.co', password: '12345678'});
        } else {
            storage.remove('requestedLogin');
        }
         */
    }

    login = auth =>
        this.props.userLogin(
            auth,
            this.props.location.state
                ? this.props.location.state.nextPathname
                : '/'
        );

    render() {
        const { handleSubmit, isLoading, theme, translate } = this.props;
        const muiTheme = getMuiTheme(theme);
        //const { primary1Color, accent1Color } = getColorsFromTheme(muiTheme);

        //es la vista del login
        return (
            <MuiThemeProvider muiTheme={muiTheme}>
                <div style={{ ...styles.main, 'backgroundImage': 'url("/fondoLogin.jpg")', width: "100%", 'backgroundRepeat': 'no-repeat' }}>
                    <Card style={styles.card}>
                        <div style={styles.avatar}>
                            <h1 style={styles.colorletra}>Sistema de Gestión de Reuniones</h1>
                            <Avatar
                                backgroundColor="#6ABA3C"
                                icon={<LockIcon />}
                                size={50}
                            />
                        </div>
                        <form onSubmit={handleSubmit((auth) => { this.login(auth); })}>
                            <div style={styles.form}>
                                <div style={styles.input}>
                                    <Field
                                        style={{ borderColor: "#871477" }}
                                        name="username"
                                        component={renderInput}
                                        floatingLabelText={translate(
                                            'aor.auth.username'
                                        ) + "  |Correo"}
                                        disabled={isLoading}
                                    />
                                </div>
                                <div style={styles.input}>
                                    <Field
                                        color="#871477"
                                        name="password"
                                        component={renderInput}
                                        floatingLabelText={translate(
                                            'aor.auth.password'
                                        ) + "  | Código"}
                                        type="password"
                                        disabled={isLoading}
                                        autoComplete='false'
                                    />
                                </div>
                            </div>
                            <CardActions>
                                <RaisedButton
                                    type="submit"
                                    buttonStyle={{ backgroundColor: "#871477" }}
                                    overlayStyle={{
                                        backgroundColor: "#e341cc"
                                    }}
                                    labelColor="#ffffff"
                                    disabled={isLoading}
                                    icon={
                                        isLoading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )
                                    }
                                    label={translate('aor.auth.sign_in')}
                                    fullWidth
                                />
                            </CardActions>

                        </form>
                    </Card>
                    <Notification />

                </div>

            </MuiThemeProvider>
        );
    }
}

Login.propTypes = {
    ...propTypes,
    authClient: PropTypes.func,
    previousRoute: PropTypes.string,
    theme: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    userLogin: PropTypes.func.isRequired,
};

Login.defaultProps = {
    theme: defaultTheme,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    translate,
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.username)
                errors.username = translate('aor.validation.required');
            if (!values.password)
                errors.password = translate('aor.validation.required');
            return errors;
        },
    }),
    connect(mapStateToProps, { userLogin: userLoginAction })
);

export default enhance(Login);
