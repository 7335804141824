import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    SelectInput,
    required
} from '../../mui/admin-on-rest';

const roleMap = {
    'admin': 'Administrador',
    'readOnly': 'Solo lectura',
    'commentator': 'Miembro',
    'secretary': 'Secretaria',
    'coordinator': 'Miembro Presidente'
};

export default (props) => (
    <Create title="Nuevo usuario" {...props}>
        <SimpleForm>
            <TextInput source="username" label="Nombre" validate={required} />
            <TextInput source="email" label="Correo" type="email" validate={required} />
            <TextInput source="password" label="Contraseña" type="password" validate={required} />
            <ReferenceInput
                label="Rol"
                source="role"
                reference="roles"
                allowEmpty
                validate={required}>
                <SelectInput optionText={choice => choice.name in roleMap ? roleMap[choice.name] : choice} />
            </ReferenceInput>
            <ReferenceArrayInput label="Consejos" source="councils" reference="councils" allowEmpty>
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceInput label="resources.acts.program" source="program" reference="programs" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
