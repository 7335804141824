import React from 'react';
import {
    SimpleForm,
    TextInput,
    DateField,
    ReferenceInput,
    SelectInput,
    ListButton,
    DeleteButton,
    RefreshButton,
    required,
    SelectArrayInput,
    ReferenceArrayInput
} from '../../mui/admin-on-rest';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import VpnKey from 'material-ui/svg-icons/communication/vpn-key';
import SubSimpleForm from '../../mui/SubSimpleForm';
import { CardActions } from 'material-ui/Card';
import Edit from '../../mui/Edit';
import storage from "../../providers/RestClient/storage";

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const Actions = ({ basePath, data, handleOpen }) => (
    <CardActions style={cardActionStyle}>
        <FlatButton
            primary
            label={"Cambiar contraseña"}
            icon={<VpnKey />}
            onClick={handleOpen}
            style={{ overflow: 'inherit' }}
        />
        {getRole() !== 'coordinator' ? <ListButton basePath={basePath} /> : null}
        {getRole() !== 'coordinator' ? <DeleteButton basePath={basePath} record={data} /> : null}
        <RefreshButton />
    </CardActions>
);

const roleMap = {
    'admin': 'Administrador',
    'readOnly': 'Solo lectura',
    'commentator': 'Miembro',
    'secretary': 'Secretaria',
    'coordinator': 'Miembro Presidente'
};

const getRole = () => storage.load('role');

export default class UserEdit extends React.Component {
    state = {
        open: false
    };

    handleOpen = () => {
        this.setState(state => ({ ...state, open: true }));
    };

    handleClose = () => {
        this.setState(state => ({ ...state, open: false }));
    };

    render() {
        return (
            <div>
                <Edit title="Editar usuario" actions={<Actions handleOpen={this.handleOpen} />} {...this.props}>
                    {
                        permissions =>
                            <SimpleForm>
                                <DateField source="created_at" label="Creado en" showTime />
                                <TextInput source="username" label="Nombre" validate={required} />
                                <TextInput source="email" label="Correo" validate={required} />
                                {
                                    permissions !== 'coordinator' ?
                                        <ReferenceInput
                                            label="Rol"
                                            source="role"
                                            reference="roles"
                                            validate={required}>
                                            <SelectInput
                                                optionText={choice => choice.name in roleMap ? roleMap[choice.name] : choice} />
                                        </ReferenceInput> :
                                        null
                                }
                                {
                                    permissions !== 'coordinator' ?
                                        <ReferenceArrayInput label="Consejos" source="councils" reference="councils" allowEmpty>
                                            <SelectArrayInput optionText="name" />
                                        </ReferenceArrayInput> :
                                        null
                                }
                                {
                                    permissions !== 'coordinator' ?
                                        <ReferenceInput label="resources.acts.program" source="program" reference="programs"
                                            allowEmpty>
                                            <SelectInput optionText="name" />
                                        </ReferenceInput> :
                                        null
                                }
                            </SimpleForm>
                    }

                </Edit>
                <Dialog
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                    autoScrollBodyContent={true}
                    bodyStyle={{ margin: 0, padding: 0 }}
                >
                    <Edit title="Cambiar contraseña" actions={false} {...this.props}>
                        <SubSimpleForm redirect="">
                            <TextInput source="password" label="Contraseña" type="password" validate={required} />
                        </SubSimpleForm>
                    </Edit>
                </Dialog>
            </div>
        );
    }
}
