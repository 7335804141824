import list from './list';
import create from './create';
import edit from './edit';
import show from './show';
import filter from './filter';

export default {
    list,
    create,
    edit,
    filter,
    show
}
