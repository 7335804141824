import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {crudGetOneSuccess} from '../admin-on-rest/actions/dataActions';

const WithIssueData = WrappedComponent => ({dispatch, ...rest}) => <WrappedComponent {...rest}/>;

const mapStateToProps = (state) => ({
    issueStates: state.admin.resources.issuestates.data || {},
    centers: state.admin.resources.centers.data || {},
    programs: state.admin.resources.programs.data || {},
    studentIssueTypes: state.admin.resources.studentissuetypes.data || {},
    studentConditionalities: state.admin.resources.studentconditionalities.data || {},
    activities: formValueSelector('record-form')(state, 'activities')
});

export default compose(
    connect(mapStateToProps, {crudGetOneSuccess}),
    WithIssueData
);
