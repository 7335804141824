import create from './create';
import edit from './edit';
import list from './list';

export default (props) => {
    return {
        create: create(props),
        edit: edit(props),
        list: list(props)
    }
};