import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FieldArray, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import StudentRow from "../StudentRow";
import storage from "../../providers/RestClient/storage";
const styles = theme => ({
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 500,
        fontSize: '0.75rem',
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    paper: {
        width: '100%',
        margin: theme.spacing.unit * 3,
    },
    root: {
        overflow: 'auto',
    },
    table: {
    },
    tableWrapper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
    },
    expand: {
        marginTop: 20,
        marginBottom: 20
    },
    expandHeader: {
        padding: 0,
        width: 48,
    },
    expandIconCell: {
        width: 48,
    },
    expandIcon: {
        transform: 'rotate(-90deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    actionInput: {
        cursor: 'pointer',
        color: 'blue',
        textDecoration: 'underline',
    }
});



class StudentsTable extends Component {
    renderStudents = ({ fields }) => (

        <TableBody>
            {


                fields.map((student, index) =>
                    <StudentRow
                        key={index}
                        id={student}
                        classes={this.props.classes}
                        programs={this.props.programs}
                        centers={this.props.centers}
                        student = {this.props.students[index]}
                        studentConditionalities={this.props.studentConditionalities}
                        studentissuetypes={this.props.studentIssueTypes}
                        openDetail={() => {
                            this.props.openDetail(index);
                        }}
                    />
                )

            }
        </TableBody>
    );

    renderCouncil() {

        let html = null;


        if (storage.load('concejo') == 'Consejo de Instituto') {
            html = <Fragment>
                <TableCell>Comentarios Instituto</TableCell>
                <TableCell>Conceptos Instituto</TableCell>
            </Fragment>;
        } else {
            html = <Fragment>
                <TableCell>Comentarios</TableCell>
                <TableCell>Conceptos</TableCell>
            </Fragment>;
        }

        return html;
    }

    render() {
        const { classes, handleSubmit } = this.props;
        //muestra los titulos de la tabla en mostrar tabla de casos de estudiantes (nuevos)
        return (
            <form className={classes.root} onSubmit={handleSubmit}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.expandHeader} />
                                <TableCell>Nombre y Código</TableCell>
                                <TableCell>Fecha solicitud</TableCell>
                                <TableCell>Solicitud y Programa</TableCell>

                                {this.renderCouncil()}
                            </TableRow>
                        </TableHead>
                        <FieldArray name="students" component={this.renderStudents} />
                    </Table>
                </div>
            </form>
        );
    }
}

StudentsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    programs: PropTypes.object,
    studentIssueTypes: PropTypes.object,
    centers: PropTypes.object,
    studentConditionalities: PropTypes.object,
    openDetail: PropTypes.func,
    handleSubmit: PropTypes.func
};

export default reduxForm()(withStyles(styles)(StudentsTable));