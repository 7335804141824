import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MuiAppBar from 'material-ui/AppBar';
import muiThemeable from 'material-ui/styles/muiThemeable';
import compose from 'recompose/compose';
import { selectCouncil, setCouncils } from "../../../../actions";
import { toggleSidebar as toggleSidebarAction } from '../../actions';
import { userLogout } from "../../actions";
import { showNotification } from "../../actions";
import { API_ENDPOINT } from "../../../../constants";
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import storage from "../../../../providers/RestClient/storage";


class AppBar extends Component {
    state = {
        open: false,
        councils: []
    };

    componentDidMount() {
        if (!this.props.selectedCouncil && storage.load('COUNCIL')) {
            this.props.selectCouncil(storage.load('COUNCIL'));
        }

        if (!this.state.councils.length) {
            const token = storage.load('token');
            const userId = storage.load('userId');
            fetch(`${API_ENDPOINT}/clients/${userId}?access_token=${token}`)
                .then(res => res.json())
                .then(user => {
                    if (!user || !user.roles)
                        return;

                    if (!user.roles.map(role => role.name).includes('admin')) {
                        if (user.councils.length) {
                            fetch(`${API_ENDPOINT}/councils?access_token=${token}&filter=${JSON.stringify({ where: { or: user.councils.map(councilId => ({ id: councilId })) } })
                                }`)
                                .then(res => res.json())
                                .then(councils => {
                                    this.props.setCouncils(councils);

                                    if (!this.props.selectedCouncil && !storage.load('COUNCIL')) {
                                        this.props.selectCouncil(councils[0].id);
                                        storage.save('COUNCIL', councils[0].id);
                                    }

                                    this.setState({ councils });
                                });
                        } else {
                            this.props.userLogout();
                            this.props.showNotification('No autorizado. El usuario no está registrado en ningún consejo.', 'warning');
                        }
                    } else {
                        fetch(`${API_ENDPOINT}/councils?access_token=${token}`)
                            .then(res => res.json())
                            .then(councils => {
                                this.props.setCouncils(councils);

                                if (!this.props.selectedCouncil && !storage.load('COUNCIL')) {
                                    this.props.selectCouncil(councils[0].id);
                                    storage.save('COUNCIL', councils[0].id);
                                }

                                this.setState({ councils });
                            });
                    }
                });
        }
    }

    handleChange = (event, index, value) => {
        this.props.selectCouncil(value);
        storage.save('COUNCIL', value);
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    getSelectedCouncilName = () => {
        if (this.props.selectedCouncil) {
            const council = this.state.councils.find(council => council.id === this.props.selectedCouncil);
            if (council) {
                storage.save('concejo', council.name);

                return ` - ${council.name}`;
            }
        }

        return '';
    };

    getChangeCouncilButton = () => {
        if (this.state.councils.length > 1) {
            if (this.props.isLoading) {
                return <FlatButton rippleColor="#dc21c2" hoverColor="#fcecfa" label="Cambiar consejo" onClick={this.handleOpen} style={{
                    marginRight: 40
                }} />
            }

            return <FlatButton rippleColor="#32072c" hoverColor="#761168" label="Cambiar consejo" onClick={this.handleOpen} />;
        }

        return null;
    };

    render() {
        const {
            title,
            toggleSidebar
        } = this.props;

        return (
            <div>
                <MuiAppBar
                    title={`${title}${this.getSelectedCouncilName()}`}
                    onLeftIconButtonClick={toggleSidebar}
                    iconElementRight={this.getChangeCouncilButton()}
                    style={{ backgroundColor: "#871477" }} />

                <Dialog
                    title="Selecciona un consejo"
                    modal={false}
                    onRequestClose={this.handleClose}
                    open={this.state.open}

                >
                    <SelectField value={this.props.selectedCouncil} onChange={this.handleChange}>
                        {
                            this.state.councils && this.state.councils.map(council =>
                                <MenuItem key={council.id} value={council.id} primaryText={council.name} />)
                        }
                    </SelectField>
                </Dialog>
            </div>
        );
    }
}

AppBar.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    setCouncils: PropTypes.func.isRequired,
    selectCouncil: PropTypes.func.isRequired,
    selectedCouncil: PropTypes.string,
    userLogout: PropTypes.func,
    showNotification: PropTypes.func
};

const enhance = compose(
    muiThemeable(), // force redraw on theme change
    connect(state => ({ selectedCouncil: state.council.selected, isLoading: state.admin.loading > 0 }), {
        selectCouncil,
        setCouncils,
        toggleSidebar: toggleSidebarAction,
        userLogout,
        showNotification
    })
);

export default enhance(AppBar);
