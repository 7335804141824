import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EnhancedButton from 'material-ui/internal/EnhancedButton';
import Tooltip from 'material-ui/internal/Tooltip';
import transitions from 'material-ui/styles/transitions';

function getStyles(props, context) {
    const {tabs} = context.muiTheme;

    return {
        root: {
            color: props.selected ? tabs.selectedTextColor : tabs.textColor,
            fontWeight: 500,
            fontSize: 14,
            width: props.width,
            textTransform: 'uppercase',
            overflow: 'visible',
            transition: transitions.easeOut(),
            padding: 0,
        },
        button: {
            overflow: 'visible',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: (props.label && props.icon) ? 72 : 48,
        },
    };
}

class Tab extends Component {
    static muiName = 'Tab';

    static propTypes = {
        /**
         * Override the inline-styles of the button element.
         */
        buttonStyle: PropTypes.object,
        /**
         * The css class name of the root element.
         */
        className: PropTypes.string,
        /**
         * Sets the icon of the tab, you can pass `FontIcon` or `SvgIcon` elements.
         */
        icon: PropTypes.node,
        /**
         * @ignore
         */
        index: PropTypes.any,
        /**
         * Sets the text value of the tab item to the string specified.
         */
        label: PropTypes.node,
        /**
         * Fired when the active tab changes by touch or tap.
         * Use this event to specify any functionality when an active tab changes.
         * For example - we are using this to route to home when the third tab becomes active.
         * This function will always recieve the active tab as it\'s first argument.
         */
        onActive: PropTypes.func,
        /**
         * @ignore
         * This property is overriden by the Tabs component.
         */
        onClick: PropTypes.func,
        /**
         * @ignore
         * Defines if the current tab is selected or not.
         * The Tabs component is responsible for setting this property.
         */
        selected: PropTypes.bool,
        /**
         * Override the inline-styles of the root element.
         */
        style: PropTypes.object,
        /**
         * If value prop passed to Tabs component, this value prop is also required.
         * It assigns a value to the tab so that it can be selected by the Tabs.
         */
        value: PropTypes.any,
        /**
         * @ignore
         * This property is overriden by the Tabs component.
         */
        width: PropTypes.string,
        touch: PropTypes.bool,
    };

    static defaultProps = {
        touch: false,
    };

    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    state = {
        isKeyboardFocused: false,
        // Not to be confused with the touch property.
        // This state is to determined if it's a mobile device.
        touch: false,
        tooltipShown: false,
    };

    setKeyboardFocus() {
        this.button.setKeyboardFocus();
    }

    showTooltip() {
        if (this.props.tooltip) {
            this.setState({tooltipShown: true});
        }
    }

    hideTooltip() {
        if (this.props.tooltip) this.setState({tooltipShown: false});
    }

    handleBlur = (event) => {
        this.hideTooltip();
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    handleFocus = (event) => {
        this.showTooltip();
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    handleMouseLeave = (event) => {
        if (!this.button.isKeyboardFocused()) {
            this.hideTooltip();
        }
        this.setState({hovered: false});
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(event);
        }
    };

    handleMouseOut = (event) => {
        if (this.props.disabled) this.hideTooltip();
        if (this.props.onMouseOut) this.props.onMouseOut(event);
    };

    handleMouseEnter = (event) => {
        this.showTooltip();

        // Cancel hover styles for touch devices
        if (!this.state.touch) {
            this.setState({hovered: true});
        }
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(event);
        }
    };

    handleTouchStart = (event) => {
        this.setState({touch: true});

        if (this.props.onTouchStart) {
            this.props.onTouchStart(event);
        }
    };

    handleKeyboardFocus = (event, isKeyboardFocused) => {
        const {disabled, onFocus, onBlur, onKeyboardFocus} = this.props;
        if (isKeyboardFocused && !disabled) {
            this.showTooltip();
            if (onFocus) {
                onFocus(event);
            }
        } else {
            this.hideTooltip();
            if (onBlur) {
                onBlur(event);
            }
        }

        this.setState({isKeyboardFocused});
        if (onKeyboardFocus) {
            onKeyboardFocus(event, isKeyboardFocused);
        }
    };

    handleClick = (event) => {
        if (this.props.onClick) {
            this.props.onClick(this.props.value, event, this);
        }
    };

    render() {
        const {
            icon,
            index, // eslint-disable-line no-unused-vars
            onActive, // eslint-disable-line no-unused-vars
            onClick, // eslint-disable-line no-unused-vars
            selected, // eslint-disable-line no-unused-vars
            label,
            buttonStyle,
            style,
            value, // eslint-disable-line no-unused-vars
            width, // eslint-disable-line no-unused-vars
            touch,
            ...other
        } = this.props;

        const styles = getStyles(this.props, this.context);

        let iconElement;
        if (icon && React.isValidElement(icon)) {
            iconElement = React.cloneElement(icon, {
                style: {
                    fontSize: 24,
                    color: (icon.props && icon.props.style && icon.props.style.color) ?
                        icon.props.style.color : styles.root.color,
                    marginBottom: label ? 5 : 0,
                },
            });
        }

        const rippleOpacity = 0.3;
        const rippleColor = this.context.muiTheme.tabs.selectedTextColor;

        const tooltipElement = <Tooltip
            label={this.props.tooltip}
            show={this.state.tooltipShown}
            touch={touch}
            style={{boxSizing: 'border-box'}}
            verticalPosition={'bottom'}
            horizontalPosition={'left'}
        />;

        return (
            <EnhancedButton
                {...other}
                ref={(ref) => this.button = ref}
                style={Object.assign(styles.root, style)}
                focusRippleColor={rippleColor}
                touchRippleColor={rippleColor}
                focusRippleOpacity={rippleOpacity}
                touchRippleOpacity={rippleOpacity}
                onTouchStart={this.handleTouchStart}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onMouseLeave={this.handleMouseLeave}
                onMouseEnter={this.handleMouseEnter}
                onMouseOut={this.handleMouseOut}
                onClick={this.handleClick}
                onKeyboardFocus={this.handleKeyboardFocus}
            >
                {tooltipElement}
                <div style={Object.assign(styles.button, buttonStyle)} >
                    {iconElement}
                    {label}
                </div>
            </EnhancedButton>
        );
    }
}

export default Tab;