import create from './create';
import edit from './edit';
import list from './list';
import show from './show';

import Description from 'material-ui/svg-icons/action/description';

const resource = 'acts';

export default {
    key: resource,
    name: resource,
    options: {
        label: 'Actas'
    },
    icon: Description,
    create,
    edit,
    list,
    show
};
