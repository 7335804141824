import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from '@material-ui/core/es/styles';
import Export from '../Export';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import {getFormValues, reset} from 'redux-form';
import {
    crudGetOne as crudGetOneAction,
    crudUpdate as crudUpdateAction,
} from '../admin-on-rest/actions/dataActions';
import translate from '../admin-on-rest/i18n/translate';
import withPermissionsFilteredChildren from '../admin-on-rest/auth/withPermissionsFilteredChildren';
import CircularProgress from "@material-ui/core/CircularProgress";

const loadingStyle = {
    padding: 20,
    textAlign: 'center'
};

const circularProgressStyle = {
    color: "#871477"
};

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
});

export class HideContentOnLoadingEdit extends Component {
    componentDidMount() {
        this.updateData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            this.props.id !== nextProps.id ||
            nextProps.version !== this.props.version ||
            this.props.issueId !== nextProps.issueId ||
            this.props.actId !== nextProps.actId
        ) {
            this.props.resetForm(this.props.form || 'record-form');
            this.updateData(nextProps.resource, nextProps.id);
        }
    }

    getBasePath() {
        const { location } = this.props;
        return location.pathname
            .split('/')
            .slice(0, -1)
            .join('/');
    }

    defaultRedirectRoute() {
        return 'list';
    }

    updateData(resource = this.props.resource, id = this.props.id) {
        this.props.crudGetOne(resource, id, this.getBasePath());
    }

    save = (record, redirect) => {
        if (this.props.onSave) {
            this.props.onSave(this.props.id, this.getBasePath(), record, redirect);
        }

        this.props.crudUpdate(
            this.props.resource,
            this.props.id,
            record,
            this.props.data,
            this.getBasePath(),
            redirect === 'prev' || redirect === 'next' ? '' : redirect
        );
    };

    render() {
        const {
            form,
            children,
            data,
            isLoading,
            resource,
            title,
            translate,
            version,
            actId,
            issueId,
            classes
        } = this.props;

        if (!children) return null;

        const basePath = this.getBasePath();

        return (
            <div className="edit-page">
                <AppBar style={{backgroundColor:"#430a3b"}} className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={() => {
                            this.props.onClose();
                            //this.save(this.props.formValues, '');
                        }} aria-label="Close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            {title}
                        </Typography>
                        <Export basePath={basePath} record={data} actId={actId} issueId={issueId} style={{color: 'white'}}/>
                    </Toolbar>
                </AppBar>
                <Card style={{ opacity: isLoading ? 0.8 : 1 }}>
                    {data ? (
                        React.cloneElement(children, {
                            form,
                            save: this.save,
                            resource,
                            basePath,
                            record: data,
                            translate,
                            version,
                            redirect:
                                typeof children.props.redirect === 'undefined'
                                    ? this.defaultRedirectRoute()
                                    : children.props.redirect,
                        })
                    ) : (
                        !isLoading && <CardContent>&nbsp;</CardContent>
                    )}
                    {
                        !data && isLoading &&
                        <div style={loadingStyle}>
                            <CircularProgress color="primary" disableShrink={true} style={circularProgressStyle}/>
                            <br/>
                            Cargando
                        </div>
                    }
                </Card>
            </div>
        );
    }
}

HideContentOnLoadingEdit.propTypes = {
    actions: PropTypes.element,
    children: PropTypes.node,
    crudGetOne: PropTypes.func.isRequired,
    crudUpdate: PropTypes.func.isRequired,
    data: PropTypes.object,
    hasDelete: PropTypes.bool,
    hasShow: PropTypes.bool,
    hasList: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    title: PropTypes.any,
    translate: PropTypes.func,
    version: PropTypes.number.isRequired,
    onSave: PropTypes.func,
    form: PropTypes.string,
};

function mapStateToProps(state, props) {
    return {
        id: decodeURIComponent(props.match.params.id),
        data: state.admin.resources[props.resource]
            ? state.admin.resources[props.resource].data[
                decodeURIComponent(props.match.params.id)
                ]
            : null,
        isLoading: state.admin.loading > 0,
        version: state.admin.ui.viewVersion,
        formValues: getFormValues('issue-form')(state)
    };
}

const enhance = compose(
    connect(mapStateToProps, {
        crudGetOne: crudGetOneAction,
        crudUpdate: crudUpdateAction,
        resetForm: reset,
    }),
    translate,
    withPermissionsFilteredChildren,
    withStyles(styles),
);

export default enhance(HideContentOnLoadingEdit);
