import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';

const TitleOrNameField = ({ record = {}, elStyle }) => {
    const studentName = get(record, 'student_name');
    const studentCode = get(record, 'student_code');
    return <span style={elStyle}>{get(record, 'title') || `${studentName} ${studentCode ? `Código: ${studentCode}` : ''}`}</span>;
};

TitleOrNameField.propTypes = {
    addLabel: PropTypes.bool,
    elStyle: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object
};

const PureTextField = pure(TitleOrNameField);

PureTextField.defaultProps = {
    addLabel: true,
};

export default PureTextField;
