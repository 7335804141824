//Backend Producción
//export const API_ENDPOINT = 'https://siger.uis.edu.co/api';
//export const API_ENDPOINT = 'http://200.16.118.98/api';
export const API_ENDPOINT = 'https://siger.uis.edu.co/api';

//constantes
export const PREVENT_USER_CONFIRMATION = 'PREVENT_USER_CONFIRMATION';
//id de estudiantes en la base de datos
export const ISSUE_TYPE_STUDENTS = '5c8580e6d6dca411c265989a';
export const OLD_ISSUE_TYPE_STUDENTS = '5ac2af6ebe73a72aba3a7daa';
