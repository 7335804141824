import React, {Component} from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    ReferenceField,
    FunctionField,
    SingleFieldList,
    TabbedShowLayout,
    FileField,
    Tab, ReferenceInput, TextInput
} from '../../mui/admin-on-rest';
import ReferenceIssuesField from '../../mui/ReferenceIssuesField';
import OptionTextChipField from '../../mui/OptionTextChipField';
import ConditionalField from '../../mui/ConditionalField';
import {EmbeddedArrayField} from 'aor-embedded-array';
import Dialog from 'material-ui/Dialog';
import moment from 'moment';
import {connect} from 'react-redux';
import storage from "../../providers/RestClient/storage";

import {ISSUE_TYPE_STUDENTS} from '../../constants';
import RichTextInput from "aor-rich-text-input";

const Title = ({record}) => {
    return <span>Acta {record ? `${record.number}` : ''}</span>;
};

const IssueTitle = ({record}) => (
    <span>{record ? record.title : 'Ver tema'}</span>
);

const defaultIssuesFilter = {
    fields: {
        id: true,
        title: true,
        student_name: true,
        state: true,
        center: true,
        program: true,
        student_issue_type: true,
    },
};

const renderIssueTitle = (record, data) => {
    return (
        record.title ||
        `${record.student_name}
             ${record.student_issue_type ? `- ${(data.studentIssueTypes[record.student_issue_type] || {}).name}` : ''}
             ${record.program ? `- ${(data.programs[record.program] || {}).name}` : ''}
             ${record.center ? `- ${(data.centers[record.center] || {}).name}` : ''}`
    );
};

class ActShow extends Component {
    state = {
        actId: this.props.location.pathname.split('/').slice(-2, -1).join('/'),
        issueId: '',
        open: false,
        userId: storage.load('userId'),
        role: storage.load('role')
    };

    handleOpen = (issueId) => {
        this.setState(state => ({...state, open: true, issueId: issueId}));
    };

    handleClose = () => {
        this.setState(state => ({...state, open: false, issueId: '', actId: ''}));
    };

    renderDialog = () => {
        if (!this.props.isIssuePublic(this.state.issueId)) {
            return (
                <div style={{padding: '8px'}}>
                    <p>El acta no es pública</p>
                </div>
            );
        } else {
            return (
                <Show
                    match={{params: {id: this.state.issueId}}}
                    location={{pathname: `/issues/${this.state.issueId}/show`}}
                    resource="issues"
                    options={{label: 'Temas'}}
                    title={<IssueTitle/>}
                >
                    <SimpleShowLayout>
                        <DateField source="created_at" label="Creado en" showTime/>
                        <ConditionalField condition={record => record.type !== ISSUE_TYPE_STUDENTS}>
                            <ReferenceField label="resources.acts.type" source="type" reference="issuetypes"
                                            linkType={false} allowEmpty>
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="title" label="Título"/>
                        </ConditionalField>
                        <ReferenceField label="resources.acts.council" source="council" reference="councils"
                                        linkType={false}
                                        allowEmpty>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField label="resources.acts.state" source="state" reference="issuestates"
                                        linkType={false}
                                        allowEmpty>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ConditionalField condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                            <ReferenceField label="Tipo de caso de estudiante" source="student_issue_type"
                                            reference="studentissuetypes"
                                            linkType={false}
                                            allowEmpty>
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="student_code" label="Código"/>
                            <TextField source="student_name" label="Nombre" options={{fullWidth: true}}/>
                        </ConditionalField>
                        <ReferenceField label="resources.acts.program" source="program" reference="programs"
                                        linkType={false}
                                        allowEmpty>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField label="resources.acts.center" source="center" reference="centers"
                                        linkType={false}
                                        allowEmpty>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ConditionalField condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                            <TextField source="start_period" label="Inicio"/>
                            <TextField source="level" label="Nivel"/>
                            <TextField source="gpa" label="Promedio acumulado"/>
                            <TextField source="cc" label="Créditos cursados"/>
                            <TextField source="ca" label="Créditos aprobados"/>
                            <TextField source="email" label="correo"/>
                            <TextField source="cm" label="Créditos matriculados"/>
                            <ReferenceField label="Condicionalidad" source="student_conditionality"
                                            reference="studentconditionalities"
                                            linkType={false}
                                            allowEmpty>
                                <TextField source="name"/>
                            </ReferenceField>
                            <RichTextField source="request" label="Solicitud"/>
                        </ConditionalField>
                        <RichTextField source="description" label="Descripción"/>
                        <FileField source="attachments" src="url" title="filename"/>
                        <ConditionalField condition={record => record.type !== ISSUE_TYPE_STUDENTS}>
                            <EmbeddedArrayField source="feedbacks" label="Conceptos">
                                <ReferenceField label="Consejo" source="council" reference="councils" linkType={false}
                                                allowEmpty>
                                    <TextField source="name"/>
                                </ReferenceField>
                                <RichTextField source="description" label="Descripción"/>
                            </EmbeddedArrayField>
                        </ConditionalField>
                        <ConditionalField condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                            <RichTextField source="inline_comment" label="Comentarios"/>
                            <RichTextField source="inline_feedback" label="Conceptos"/>
                        </ConditionalField>
                    </SimpleShowLayout>
                </Show>
            );
        }
    };

    render = () => {
        return (
            <div>
                <Show title={<Title/>} {...this.props} hasEdit={false}>
                    <TabbedShowLayout>
                        <Tab label="Información Básica">
                            <TextField source="number" label="Número de acta"/>
                            <DateField source="begins_at" label="Fecha de inicio" showTime/>
                            <DateField source="ends_at" label="Fecha de finalización" showTime/>
                            <TextField source="place" label="Lugar"/>
                            <ReferenceField source="council" label="Consejo" reference="councils" allowEmpty
                                            linkType={false}>
                                <TextField source="name"/>
                            </ReferenceField>
                        </Tab>
                        <Tab label="Quorum">
                            <EmbeddedArrayField source="quorum" label="Quorum">
                                <TextField source="name" label="Nombre"/>
                                <TextField source="charge" label="Cargo"/>
                                <FunctionField label="¿Asistió?" render={record => record.assisted ? `Sí` : 'No'}/>
                            </EmbeddedArrayField>
                        </Tab>
                        <Tab label="Invitados">
                            <EmbeddedArrayField source="invited" label="Invitados">
                                <TextField source="name" label="Nombre"/>
                                <TextField source="charge" label="Cargo"/>
                                <TextField source="justification" label="Justificación"/>
                                <FunctionField label="¿Asistió?" render={record => record.assisted ? `Sí` : 'No'}/>
                            </EmbeddedArrayField>
                        </Tab>
                        <Tab label="Actividades">
                            <EmbeddedArrayField source="activities" label="Actividades">
                                <TextField source="title" label="Título"/>
                                <FunctionField label="Hora de inicio"
                                               render={record => moment(record.begins_at).format('hh:mm A')}/>
                                <FunctionField label="Hora de finalización"
                                               render={record => moment(record.ends_at).format('hh:mm A')}/>
                                <FunctionField label="¿Realizada?" render={record => record.completed ? `Sí` : 'No'}/>
                                <ReferenceIssuesField label="Temas" source="issues" reference="issues" allowEmpty
                                                     filter={defaultIssuesFilter}>
                                    <SingleFieldList>
                                        <OptionTextChipField
                                            optionText={renderIssueTitle}
                                            style={{margin: 4, alignItems: 'center'}}
                                            labelStyle={{
                                                whiteSpace: 'normal',
                                                lineHeight: 'normal',
                                                paddingTop: '8px',
                                                paddingBottom: '8px'
                                            }}
                                            onClick={this.handleOpen}
                                        />
                                    </SingleFieldList>
                                </ReferenceIssuesField>
                                <EmbeddedArrayField source="feedbacks" label="Conceptos">
                                    <ReferenceField label="Consejo" source="council" reference="councils"
                                                    linkType={false}
                                                    allowEmpty>
                                        <TextField source="name"/>
                                    </ReferenceField>
                                    <RichTextField source="description" label="Descripción"/>
                                </EmbeddedArrayField>
                            </EmbeddedArrayField>
                        </Tab>
                    </TabbedShowLayout>
                </Show>
                <Dialog
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                    autoScrollBodyContent={true}
                    bodyStyle={{margin: 0, padding: 0}}
                >
                    {
                        this.renderDialog()
                    }
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    location: state.routing.location,
    isActEditable: (actId) => state.admin.resources.acts.data[actId] && state.admin.resources.acts.data[actId].editable,
    isIssuePublic: (issueId) => state.admin.resources.issues.data[issueId] && state.admin.resources.issues.data[issueId].public,
});

export default connect(mapStateToProps)(ActShow);
