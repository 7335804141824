import React from 'react';
import PropTypes from 'prop-types';
import FormInput from '../admin-on-rest/mui/form/FormInput';

const ConditionalFormTab = ({ label, icon, children, condition, ...rest }) => (
    <span>
        {React.Children.map(
            children,
            input => (input ? <FormInput input={input} {...rest} /> : null)
        )}
    </span>
);

ConditionalFormTab.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.any,
    children: PropTypes.any,
    condition: PropTypes.func,
};

ConditionalFormTab.defaultProps = {
    condition: () => true
};

export default ConditionalFormTab;
