const selectCouncil = (selected) => {
    return {
        type: 'SELECT_COUNCIL',
        selected
    }
};

const setCouncils = (list) => {
    return {
        type: 'SET_COUNCILS',
        list
    }
};

const togglePrompt = (show = false, message = '', callback = undefined) => {
    return {
        type: 'TOGGLE_PROMPT',
        show,
        message,
        callback
    }
};

export {
    selectCouncil,
    setCouncils,
    togglePrompt
}
