import React, { Fragment } from 'react';
import {
    TabbedForm,
    FormTab,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    required,
    ListButton,
    DeleteButton,
    RefreshButton,
    LongTextInput,
    FileField,
    FileInput,
    FormDataConsumer
} from '../../mui/admin-on-rest';
import Edit from '../../mui/Edit';
import { CardActions } from 'material-ui/Card';
import { EmbeddedArrayInput } from 'aor-embedded-array';
import CarouselEmbeddedArrayInput from '../../mui/CarouselEmbeddedArrayInput';
import TimeInput from '../../mui/TimeInput';
import DateTimeInput from 'aor-datetime-input';
import Sortable from '../../mui/Sortable';
import Export from '../../mui/Export';
import { connect } from 'react-redux';
import storage from "../../providers/RestClient/storage";
import ShowTableButton from "../../mui/ShowTableButton";
import IssuesInput from '../../mui/IssuesInput';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const Actions = ({ basePath, data, programs, centers, studentIssueTypes, studentConditionalities, isLoading }) => (
    <CardActions style={cardActionStyle}>
        <Export
            basePath={basePath}
            disabled={isLoading}
            record={data}
            programs={programs}
            centers={centers}
            studentIssueTypes={studentIssueTypes}
            studentConditionalities={studentConditionalities} />
        <ListButton basePath={basePath} />
        <DeleteButton basePath={basePath} record={data} />
        <RefreshButton />
    </CardActions>
);

class ActEdit extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            actId: this.props.location.pathname.split('/').slice(-1).join(''),
            userName: storage.load('userName'),
        };
    }

    getCouncilFilter = () => {
        if (this.props.userCouncils.length) {
            return { where: { or: this.props.userCouncils.map(council => ({ id: council.id })) } }
        }

        return {};
    };

    render() {
        return (
            <div>
                <Edit title="Editar acta" actions={<Actions />} {...this.props}>
                    <TabbedForm redirect="edit" submitOnEnter={true} >
                        <FormTab label="Información Básica">
                        <TextInput source="number" label="Número de acta" validate={required} />
                        <DateTimeInput source="begins_at" label="Fecha de inicio"
                            optionsTime={{ format: 'ampm' }} />
                        <DateTimeInput source="ends_at" label="Fecha de finalización"
                            optionsTime={{ format: 'ampm' }} />
                        <BooleanInput label="¿Editable?" source="editable" />
                        <ReferenceInput label="resources.acts.council" source="council" reference="councils"
                            allowEmpty validate={required} filter={this.getCouncilFilter}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <TextInput source="place" label="Lugar" />
                        <FileInput
                            source="pdf"
                            label="Acta escaneada"
                            accept="application/pdf">
                            <FileField source="url" title="filename" />
                        </FileInput>
                        </FormTab>
                        <FormTab label="Quorum">
                            <EmbeddedArrayInput source="quorum" label="Quorum">
                                <TextInput source="name" label="Nombre" validate={required} />
                                <TextInput source="charge" label="Cargo" />
                                <BooleanInput label="¿Asistió?" source="assisted" />
                            </EmbeddedArrayInput>
                        </FormTab>
                        <FormTab label="Invitados">
                            <EmbeddedArrayInput source="invited" label="Invitados">
                                <TextInput source="name" label="Nombre" validate={required} />
                                <TextInput source="charge" label="Cargo" />
                                <LongTextInput source="justification" label="Justificación" />
                                <BooleanInput label="¿Asistió?" source="assisted" />
                            </EmbeddedArrayInput>
                        </FormTab>
                        <FormTab label="Orden del Día">
                            <Sortable source="activities">
                                <TextInput source="title" label="Título" validate={required} />
                                <TimeInput source="begins_at" label="Hora de inicio"
                                    options={{ format: 'ampm' }} />
                                <TimeInput source="ends_at" label="Hora de finalización"
                                    options={{ format: 'ampm' }} />
                            </Sortable>
                        </FormTab>
                        <FormTab label="Actividades">
                            <CarouselEmbeddedArrayInput
                                source="activities"
                                label="Actividades"
                                allowAdd={false}
                                allowRemove={false}>
                                <LongTextInput source="title" label="Título" validate={required} />
                                <BooleanInput source="completed" label="¿Realizada?" />
                                <ShowTableButton openDetail={this.handleOpen} actId={this.state.actId} />
                                <IssuesInput label='Agregar temas' source='issues' reference='_issues' />
                            </CarouselEmbeddedArrayInput>
                        </FormTab>
                    </TabbedForm>
                </Edit>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userCouncils: state.council.list || [],
    location: state.routing.location,
});

export default connect(mapStateToProps)(ActEdit);
