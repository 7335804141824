import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    DateField,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    LongTextInput,
    TextInput,
    FileInput, required, FileField
} from '../admin-on-rest';

import HideContentOnLoadingEdit from '../HideContentOnLoadingEdit';
import ConditionalTabbedForm from '../ConditionalTabbedForm';
import ConditionalFormTab from '../ConditionalFormTab';
import ConditionalInput from '../ConditionalInput';
import RichTextInput from 'aor-rich-text-input';
import TinyMCEInput from '../TinyMCEInput';
import Comments from '../EmbeddedArrayInput';
import ConditionalReferenceInput from '../ConditionalReferenceInput';
import DefaultSelectInput from '../DefaultSelectInput';
import { EmbeddedArrayInput } from 'aor-embedded-array';
import DateTimeInput from 'aor-datetime-input';
import Divider from '@material-ui/core/Divider';

import Toolbar from './toolbar';
import { ISSUE_TYPE_STUDENTS, OLD_ISSUE_TYPE_STUDENTS } from '../../constants';
import { ACCEPTED_MIME_TYPES, CREATE_COUNCIL_FILTER } from '../../resources/Issues/constants';


import storage from '../../providers/RestClient/storage';

//new styles for textfields

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    body: {
        backgroundColor: '#ffffff',
        display: 'flex',
        flex: 1,
        overflowY: 'hidden',
        overflowX: 'scroll',
    },
    content: {
        flex: 1,
        padding: '2em',
    },
    loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 16,
        zIndex: 1200,
    },

    rightObject: {
        display: 'inline-block',
        float: 'right',
        marginTop: '-13px',

    },

    horizontalMargin: {
        display: 'inline-block',
        marginRight: 32
    },

    horizontalFecha: {
        display: 'inline-block',
        marginRight: 6
    },

    divider: {
        marginTop: '-22px',
        height: '2px',
        width: '100%',
        backgroundColor: '#871477'
    },

    horizontal: {
        display: 'inline-block',
        width: '100%',
    },

    horizontal2: {
        display: 'inline-block',

    },
    horizontalMaxWidth: {
        display: 'inline-block',
        width: '260px',

    },
    horizontalMaxWidth2: {
        display: 'inline-block',
        width: '100%',

    },

    horizontalMaxWidth3: {
        display: 'inline-block',
        width: '320px',

    },

    horizontal3: {
        display: 'inline-block',
        width: '610px',
    },

    actas: {
        display: 'inline-block',
        width: '200px',
        marginRight: 32,

    },

    prueba2: {
        display: 'inline-block',
        width: '350'

    },

    prueba: {
        display: 'inline-block',
        marginRight: 32,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 10,
    },
    textField: {
        margin: "40px",
        marginLeft: "35px",
        marginRight: "10px",
        width: 250,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
};
//


class IssueEdit extends Component {
    getPolyfillParams = () => {
        const { actId, issueId, onSave, onClose } = this.props;

        return {
            onSave,
            onClose,
            actId,
            issueId,
            match: {
                params: {
                    id: issueId
                }
            },
            location: {
                pathname: `/issues/${issueId}`
            },
            resource: 'issues',
        };
    };

    showConcepts = (role) => {
        const userId = storage.load('userId');

        if (storage.load('concejo') == 'Consejo de Instituto' && (role !== "readOnly")) {
            return (
                <ConditionalFormTab key={1} label="Comision Estudiantes" >
                    <Comments source="comments" label="Conceptos coordinadores" userId={userId}>
                        <ConditionalReferenceInput label="Creado por" source="createdBy" reference="clients" allowEmpty
                            filter={{ fields: { councils: false, created_at: false, updated_at: false } }}
                            options={{ disabled: true }}>
                            <DefaultSelectInput optionText={user => user.username || user.email}
                                default={userId}
                                options={{ disabled: true }} />
                        </ConditionalReferenceInput>
                        <ConditionalReferenceInput label="Tipo de concepto" source="type" reference="commenttypes"
                            allowEmpty>
                            <SelectInput optionText="name" />
                        </ConditionalReferenceInput>
                        <LongTextInput source="description" label="Descripción" />
                    </Comments>
                </ConditionalFormTab>
            )
        }

        if (role !== 'admin') {
            return false;
        }


    };

    render() {
        const {
            issueIndex,
        } = this.props;

        const toolbar =
            <Toolbar
                showPrev={() => this.props.issueIndex > 0}
                showNext={() => this.props.issueIndex !== (this.props.issues.length - 1)} />;

        return (
            <HideContentOnLoadingEdit
                form='issue-form'
                title={`Editar tema ${issueIndex + 1}`}
                {...this.getPolyfillParams()}>
                {
                    permissions =>
                        <ConditionalTabbedForm
                            redirect={""}
                            toolbar={toolbar}>
                            <ConditionalFormTab label="Información" style={styles.container} >
                                <BooleanInput style={styles.horizontalMargin} source="public" label="¿Público?" />
                                <BooleanInput style={styles.horizontalMargin} source="editable" label="¿Editable?" />
                                <DateField style={styles.rightObject} source="created_at" label="Creado en" showTime />
                                <Divider style={styles.divider} variant="inset" />
                                <ConditionalInput style={styles.horizontal} >

                                    <ReferenceInput style={styles.horizontal} label="Tipo de caso"
                                        source="type"
                                        reference="issuetypes"
                                        allowEmpty
                                    >
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                </ConditionalInput>
                                <ReferenceInput style={styles.horizontalMargin}
                                    label="resources.acts.council"
                                    source="council"
                                    reference="councils"
                                    filter={CREATE_COUNCIL_FILTER(this.props.userCouncils)}
                                    allowEmpty>

                                    <SelectInput style={styles.horizontalMargin} optionText="name" />

                                </ReferenceInput>
                                {/* Parte de estado */}
                                <ReferenceInput style={styles.horizontalMargin} label="resources.acts.state" source="state"
                                    reference="issuestates" allowEmpty>
                                    <SelectInput style={styles.horizontalMargin} optionText="name" />
                                </ReferenceInput>
                                <ConditionalInput style={styles.horizontalMargin} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <ReferenceInput label="Tipo de caso de estudiante" source="student_issue_type"
                                        reference="studentissuetypes" allowEmpty>
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                </ConditionalInput>
                                {/* actas */}

                                <ReferenceInput style={styles.prueba} label="resources.acts.program" source="program"
                                    reference="programs" allowEmpty>
                                    <SelectInput style={styles.prueba} optionText="name" />
                                </ReferenceInput>
                                <ReferenceInput style={styles.horizontalMargin} label="resources.acts.center" source="center"
                                    reference="centers" allowEmpty>
                                    <SelectInput style={styles.horizontalMargin} optionText="name" />
                                </ReferenceInput>

                                <ConditionalInput style={styles.horizontal3} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <TextInput style={styles.horizontalMargin} source="student_code" label="Código" validate={required} />
                                    <TextInput style={styles.horizontalMaxWidth3} source="student_name" label="Nombre" options={{ fullWidth: true }} validate={required} />
                                </ConditionalInput>

                                <ConditionalInput style={styles.horizontal} condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <TextInput style={styles.horizontalMargin} source="start_period" label="Inicio" validate={required} />
                                    <TextInput style={styles.horizontalMargin} source="level" label="Nivel" validate={required} />
                                    <TextInput style={styles.horizontalMargin} source="gpa" label="Promedio acumulado" />
                                    <TextInput style={styles.horizontalMargin} source="cc" label="Créditos cursados" />
                                    <TextInput style={styles.horizontalMargin} source="ca" label="Créditos aprobados" />
                                    <TextInput style={styles.horizontalMargin} source="cm" label="Créditos matriculados" />
                                    <DateTimeInput style={styles.horizontalFecha} source="request_date" label="Fecha de solicitud" optionsTime={{ format: 'ampm' }} />
                                    <ReferenceInput style={styles.horizontalMargin} label="Condicionalidad" source="student_conditionality"
                                        reference="studentconditionalities" allowEmpty validate={required}>
                                        <SelectInput style={styles.horizontalMargin} optionText="name" />
                                    </ReferenceInput>
                                    <RichTextInput source="request" label="Solicitud" toolbar={[['bold']]} />
                                </ConditionalInput>

                                <TinyMCEInput source="description" label="Descripción" />
                                <FileInput
                                    source="attachments"
                                    label="Archivos de soporte"
                                    accept={ACCEPTED_MIME_TYPES}
                                    multiple>
                                    <FileField source="url" title="filename" />
                                </FileInput>
                                <ConditionalInput condition={record => record.type === ISSUE_TYPE_STUDENTS}>
                                    <RichTextInput source="inline_comment" label="Comentarios" toolbar={[['bold']]} />
                                    <RichTextInput source="inline_feedback" label="Conceptos" toolbar={[['bold']]} />
                                </ConditionalInput>
                            </ConditionalFormTab>
                            {
                                this.showConcepts(permissions)
                            }
                        </ConditionalTabbedForm>
                }
            </HideContentOnLoadingEdit>
        );
    }
}

IssueEdit.propTypes = {
    issueIndex: PropTypes.number,
    issueId: PropTypes.string
};

export default IssueEdit;
