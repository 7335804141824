import storage from './storage';

export const authClient = (API_ENDPOINT, loginApiUrl, noAccessPage = '/login') => {

    return function handler(type, params) {
        if (type === 'AUTH_LOGIN') {
            const request = new Request(API_ENDPOINT + loginApiUrl, {
                method: 'POST',
                body: JSON.stringify({ email: params.username, password: params.password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });

            return fetch(request)
                .then((response) => {
                    // if (response.status < 200 || response.status >= 300) {
                    //     const infor = {
                    //         "id": "otcCdE2MJl95pJf1lgMqvh8NmFmnZPe0PG43aDvTJW13Cqrdwr3IRGbEFhRWScrL",
                    //         "ttl": 1209600,
                    //         "created": "2022-11-17T00:21:09.752+00:00",
                    //         "userId": "636ba7b64d49a8038f9bb340",
                    //         "user": {
                    //             "program": null,
                    //             "username": "Usuario Lectura (no borrar)",
                    //             "email": "userReadOnly@siger.co",
                    //             "id": "636ba7b64d49a8038f9bb340",
                    //             "created_at": "2022-11-09T13:14:30.943+00:00",
                    //             "updated_at": "2022-11-17T00:21:06.079+00:00",
                    //             "councils": [
                    //                 "5ac2afaabe73a72aba3a7db0"
                    //             ],
                    //             "roles": [
                    //                 {
                    //                     "id": "5aea4fddc1a4d714dcfc77d9",
                    //                     "name": "readOnly",
                    //                     "created": "2018-05-02T23:55:09.456Z",
                    //                     "modified": "2018-05-02T23:55:09.456Z"
                    //                 }
                    //             ]
                    //         }
                    //     }
                    //     return infor;
                    // }
                    if (response.status === 401){
                        throw new Error('No autorizado. Credenciales incorrectas.'); 
                    }

                    return response.json();
                })
                .then((loginInfo) => {
                    console.log(loginInfo)
                    if (params.username === 'consulta@siger.co') {
                        storage.save('consult', true, loginInfo.ttl);
                    } else {
                        storage.save('consult', false, loginInfo.ttl);

                        if (!loginInfo.user.roles.map(role => role.name).includes('admin')) {
                            if (loginInfo.user.councils.length) {
                                storage.save('COUNCIL', loginInfo.user.councils[0]);
                            } else {
                                storage.clear();
                                throw new Error('No autorizado. El usuario no está registrado en ningún consejo.');
                            }
                        }
                    }


                    storage.save('token', loginInfo.id, loginInfo.ttl);

                    storage.save('userId', loginInfo.userId, loginInfo.ttl);
                    storage.save('userName', loginInfo.user.username, loginInfo.ttl);
                    storage.save('role', loginInfo.user.roles[0].name, loginInfo.ttl);

                    storage.save('email', params.username, loginInfo.ttl);
                    storage.save('code', params.password, loginInfo.ttl);

                    storage.save('program', loginInfo.user.program, loginInfo.ttl);
                });
        }
        if (type === 'AUTH_LOGOUT') {
            storage.clear();
            return Promise.resolve();
        }
        if (type === 'AUTH_ERROR') {
            const status = params.message.status;
            if (status === 401 || status === 403) {

                // storage.clear();
                //return Promise.reject();
            }
            return Promise.resolve();
        }
        if (type === 'AUTH_CHECK') {
            const token = storage.load('token');
            if (token) {
                return Promise.resolve();
            } else {
                storage.clear();
                return Promise.reject({ redirectTo: noAccessPage });
            }
        }
        if (type === 'AUTH_GET_PERMISSIONS') {
            const role = storage.load('role');
            return Promise.resolve(role);
        }

        return Promise.reject('Unknown method');
    };
};
