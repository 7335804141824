export default (previousState = {selected: undefined, list: []}, {type, selected, list = []}) => {
    switch (type) {
        case 'SELECT_COUNCIL':
            return {
                ...previousState,
                selected
            };
        case 'SET_COUNCILS':
            return {
                ...previousState,
                list
            };
        default:
            return previousState;
    }
}
