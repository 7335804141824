const initialState = {show: false, message: '', callback: undefined};

export default (previousState = initialState, { type, show, message, callback }) => {
    switch (type) {
        case 'TOGGLE_PROMPT':
            return {
                show,
                message,
                callback
            };
        default:
            return previousState;
    }
};